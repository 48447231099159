<template>
  <div class="h-screen bg-col-white">
    <IndexNavbar />
    <div class="flex justify-center ">
      <div class="width-small  bg-col-dark-2 color-white">
        <div class="title my-4">
          Create Your First Bot
        </div>
        <div
          class="menu-1 menu-active w-full py-4 px-2 my-2 cursor-pointer font-small"
          id="newBot"
          @click="setActive"
        >
          New Bot
        </div>
        <div
          class="menu-1 w-full py-4 px-2 my-2 font-small custom-cursor"
          id="addQuestions"
          v-if="botTitle.length === 0"
          @click="setActive"
        >
          Add Questions
        </div>

        <div
          class="menu-1 w-full py-4 px-2 my-2 cursor-pointer font-small"
          id="addQuestions"
          v-if="botTitle.length > 0"
          @click="setActive"
        >
          Add Questions
        </div>

        <div class="custom-preview flex justify-center mt-8">
          <Preview
            :question="previewQuestion"
            :bot_name="botTitle"
            v-if="addQuestions"
          />
        </div>
      </div>
      <div class="width-large relative h-full overflow-hidden">
        <div
          class="flex justify-center items-center h-full flex-col-reverse"
          v-show="newBot"
        >
          <button
            class="custom-publish-bot bg-col-grayDark rounded text-xl my-2 custom-cursor"
            @click="openAddQuestionsPage"
            v-if="!botTitle"
          >
            Start adding questions
          </button>

          <button
            class="custom-publish-bot bg-col-blue2 color-gray rounded text-xl my-2"
            @click="openAddQuestionsPage"
            v-if="botTitle"
          >
            Start adding questions
          </button>

          <div
            class="big-card rounded flex justify-evenly flex-col p-8"
            :class="theme == 'dark' ? 'customDark' : ''"
          >
            <div class="flex justify-center items-center">
              <select
                name="cars"
                class="custom-select w-full card-item bg-col-white rounded shadow-sm-blue"
                id="cars"
                v-model="selectedProject"
                aria-placeholder="Select project"
              >
                <option value="" disabled selected>Projects Loading....</option>
                <option
                  v-for="(project, index) in projects"
                  :key="index"
                  :value="project"
                  >{{ project.title }}
                </option>
              </select>

              <div
                class="text-2xl cursor-pointer color-white ml-2"
                @click="goToCreateProject"
              >
                <div
                  class="bg-col-green custom-add-option flex justify-center items-center"
                >
                  +
                </div>
              </div>
            </div>

            <input
              type="text"
              class="card-item bg-col-white rounded shadow-sm-blue"
              placeholder="Add Title"
              v-model="botTitle"
            />
            <input
              type="text"
              class="shadow-sm-blue card-item bg-col-white rounded"
              placeholder="Add Intro text"
              v-model="botIntroText"
            />
            <div class="box rounded shadow-sm-blue p-2">
              <h3 class="py-2 font-semibold mb-4">Add Theme</h3>

              <div class="theme flex flex-row justify-around items-center">
                <div
                  class="theme-card relative t-card-1 shadow-sm-blue rounded"
                  :class="theme == 'default' ? 'theme-shadow' : ''"
                  @click="changeTheme('default')"
                >
                  <h6 class="absolute font-medium">Default</h6>
                </div>

                <div
                  class="theme-card relative t-card-3 shadow-sm-blue rounded"
                  :class="theme == 'dark' ? 'theme-shadow' : ''"
                  @click="changeTheme('dark')"
                >
                  <h6 class="absolute font-medium">Dark</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="h-full p-4 w-full" v-show="addQuestions">
          <div
            class="custom-publish-screen flex items-center justify-between custom-width-90"
          >
            <div
              class="custom-box-ne rounded p-2 flex items-center flex-row-reverse"
            >
              <div class="font-semibold text-center ml-2">
                Add name and email
              </div>
              <div class="custom-check-container" @click="valuecheck">
                <input
                  type="checkbox"
                  class="custom-addname-checkbox rounded"
                />
              </div>
            </div>
            <div class=" flex justify-between ">
              <div
                class="custom-add-question rounded bg-col-blue color-white flex justify-center items-center mr-2"
                @click="addQuestion"
              >
                Add Questions
              </div>
              <button
                class="custom-button-publish rounded bg-col-green color-white"
                @click="modalOpen = !modalOpen"
                v-if="questionList.length > 0"
              >
                Publish
              </button>
              <button
                class="custom-button-publish rounded bg-col-grayDark color-white"
                v-if="questionList.length == 0"
              >
                Publish
              </button>
            </div>
          </div>
          <div
            class="custom-question-container flex flex-col items-center overflow-auto relative "
            v-if="questionList.length !== 0"
          >
            <div class="questions w-full">
              <Question
                :questionList="questionList"
                :addOptionInList="addOptionInList"
                :setAnswerSingle="setAnswerSingle"
                :removeAnswerSingle="removeAnswerSingle"
                :emptyQuestionDetails="emptyQuestionDetails"
                :setAnswerMultiple="setAnswerMultiple"
                :removeMultipleAnswers="removeMultipleAnswers"
                :isOptionInMultipleOptions="isOptionInMultipleOptions"
                :removeQuestion="removeQuestion"
                :setPreviewQuestion="setPreviewQuestion"
                :previewQuestion="previewQuestion"
              />
            </div>
          </div>
          <div
            class="custom-question-container flex flex-col items-center justify-center overflow-auto relative "
            v-if="questionList.length === 0"
          >
            <Roboanimation />
            <div class="text-3xl text-center">Add some questions</div>
          </div>
        </div>
      </div>
      <div
        class="fixed custom-modal flex justify-center items-center"
        v-if="modalOpen"
      >
        <div class="custom-modal-card p-4">
          <div class="text-2xl font-semibold text-center mt-4">
            Are you sure to publish the bot?
          </div>
          <div class="flex justify-evenly">
            <button
              class="custom-button-round custom-button-green rounded-full text-2xl font-semibold"
              @click="createBot"
              v-if="!loadingButton"
            >
              Yes
            </button>

            <button
              class="custom-button-round bg-col-green custom-button-green rounded-full text-2xl font-semibold relative flex justify-center items-center"
              @click="createBot"
              v-if="loadingButton"
            >
              <Buttonloading />
            </button>

            <button
              class="custom-button-round custom-button-red rounded-full text-2xl font-semibold"
              @click="modalOpen = !modalOpen"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import Preview from "@/components/Preview/Preview.vue";
import Roboanimation from "@/components/Roboanimation/Roboanimation.vue";
import Buttonloading from "@/components/Buttonloading/Buttonloading.vue";
import Question from "@/components/Question/Question.vue";
import { getProjectDetails, postBot } from "@/utils/api.js";

export default {
  data() {
    return {
      newBot: true,
      addQuestions: false,
      avatarImage: null,
      introImage: null,
      backgroundImage: null,
      loadingButton: false,
      theme: "default",
      helpText: null,
      projects: [],
      selectedProject: "",
      nameAndEmail: [],
      toggleNameAndEmail: false,
      botTitle: "",
      botIntroText: "",
      botEndText: "end text is there",
      botDescription: "Description is there",
      isRequired: true,
      modalOpen: false,
      questionList: [
        {
          type: 1,
          question_text: "What is the capital of japan",
          answer: "",
          helper_text: "",
          is_required: true,

          options: [],
          multipleAnswers: [],
        },
      ],
      previewQuestion: "",
      defaultProject: "",
    };
  },
  methods: {
    valuecheck() {
      this.toggleNameAndEmail = !this.toggleNameAndEmail;
      console.log(this.toggleNameAndEmail);
    },
    setActive(e) {
      if (this.botTitle) {
        const element = document.querySelector(".menu-active");
        element.classList.remove("menu-active");
        e.target.classList.add("menu-active");
        if (e.target.id == "newBot") {
          this.addQuestions = false;
          this.newBot = true;
        } else {
          this.addQuestions = true;
          this.newBot = false;
        }
      }
    },
    openAddQuestionsPage() {
      this.addQuestions = true;
      this.newBot = false;
      document.getElementById("newBot").classList.remove("menu-active");
      document.getElementById("addQuestions").classList.add("menu-active");
    },
    addQuestion() {
      const newQuestionList = [
        ...this.questionList,
        {
          type: 4,
          question_text: "",
          is_required: this.isRequired,
          answer: "",
          options: [{ option_text: "Label", option_sequence: 1 }],
        },
      ];
      this.questionList = [...newQuestionList];
      console.log(this.questionList);
    },
    loadAllProjects: async function() {
      const res = await getProjectDetails();
      console.log(res);
      this.projects = [...res.projects];
      // console.log('------Default project------', this.$route.params.project)
      if(this.$route.params.project){
        this.filterProject = this.projects.filter((project) => {
          if(project.title === this.$route.params.project){
            return project;
          }
        })

        // console.log('-----Inside if cond.------', this.filterProject[0])
        this.selectedProject = this.filterProject[0];
      }
      else{
      this.selectedProject = res.projects[0];
      }
    },
    async createBot() {
      this.loadingButton = true;
      if (this.toggleNameAndEmail) {
        const name = {
          type: 3,
          question_text: "Let's start with your name",
          helper_text: "",
          is_required: true,
          options: [],
        };
        const email = {
          type: 3,
          question_text:
            "Also your email address, we promise we won't spam you",
          helper_text: "",
          is_required: true,
          options: [],
        };

        this.questionList.unshift(name, email);
      }

      console.log("The questionlist is after addingn:", this.questionList);

      let result = this.questionList.map(
        ({ answer, multipleAnswers, ...rest }, index) => {
          console.log(answer, multipleAnswers);
          return { ...rest, serial_number: index };
        }
      );

      console.log("the obtained result is this:", result);

      if (this.questionList.length > 0) {
        const res = await postBot(
          this.botTitle,
          this.selectedProject.id,
          this.botDescription,
          this.botIntroText,
          this.botEndText,
          this.avatarImage,
          this.introImage,
          this.backgroundImage,
          this.theme,
          this.helpText,
          result
        );
        console.log("The response of create bot is:", res);
        //this.projects = [...res.projects];
        //console.log("Bot ban gi se", this.projects);
        this.loadingButton = false;
        this.$router.push({
          name: "link",
          params: { bot_id: res.bot_details.uid },
        });
      }
    },
    addOptionInList(index, item) {
      item = item.trim();
      if (item) {
        console.log(item);
        this.questionList[index].options.push({
          option_text: item,
          option_sequence: this.questionList[index].options.length,
        });
        console.log(this.questionList[index]);
      }
    },
    setAnswerSingle(index, option) {
      this.questionList[index].answer = option;
    },
    removeAnswerSingle(option, index) {
      if (option) {
        this.questionList[index].options = this.questionList[
          index
        ].options.filter((item) => item != option);
        if (this.questionList[index].answer == option) {
          this.questionList[index].answer = "";
        }
      }
    },
    emptyQuestionDetails(index) {
      console.log("the answer was: ", this.questionList[index].answer);
      this.questionList[index].answer = "";

      this.questionList[index].question = "";
      this.questionList[index].options = [];
    },
    setAnswerMultiple(index, option) {
      if (this.questionList[index].multipleAnswers.includes(option)) {
        this.questionList[index].multipleAnswers = this.questionList[
          index
        ].multipleAnswers.filter((item) => item != option);
      } else {
        this.questionList[index].multipleAnswers.push(option);
      }
      console.log(this.questionList[index]);
    },
    removeMultipleAnswers(index, option) {
      this.questionList[index].options = this.questionList[
        index
      ].options.filter((item) => item != option);
      this.questionList[index].multipleAnswers = this.questionList[
        index
      ].multipleAnswers.filter((item) => item != option);
    },
    isOptionInMultipleOptions(index, option) {
      if (this.questionList[index].options.includes(option)) {
        return true;
      } else {
        return false;
      }
    },

    removeQuestion(index) {
      this.questionList.splice(index, 1);
    },
    changeTheme(th) {
      this.theme = th;
      console.log(this.theme);
    },
    setPreviewQuestion(question) {
      if (this.questionList.length == 1)
        this.previewQuestion = this.questionList[0];
      else {
        if (this.questionList.length === 0) {
          this.previewQuestion = "No question to be displayed";
        } else {
          this.previewQuestion = question;
        }
      }
      console.log(question);
    },
    checkUserPresence() {
      const user = localStorage.getItem("access_token");
      if (!user) {
        this.$router.push("/login");
      }
    },

    //Modified for revamp

    goToCreateProject() {
      this.$router.push("/createproject");
    },
  },
  watch: {
    questionList: {
      handler() {
        //console.log(this.questionList);
        if (this.questionList.length == 1)
          this.previewQuestion = this.questionList[0];
        else {
          if (this.questionList.length === 0) {
            this.previewQuestion = "No question to be displayed";
          }
        }
      },
      deep: true,
    },
    selectedProject: function() {
      console.log("Value of selected project is:", this.selectedProject);
    },
  },
  created() {
    this.checkUserPresence();
    this.loadAllProjects();
  },
  mounted() {
    
    this.setPreviewQuestion();
  },

  components: {
    IndexNavbar,
    Question,
    Preview,
    Roboanimation,
    Buttonloading,
  },
};
</script>

<style scoped>
.width-small {
  width: 20%;
  height: calc(100vh - 4rem);
}
.width-large {
  width: 80%;
  height: calc(100vh - 4rem);
}
.menu-1 {
  border-left: none;
  background-color: transparent;
  transition: 0.1s ease;
}
.menu-active {
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 0.3em solid rgba(255, 255, 255, 1);
}
.menu-1:hover {
  font-weight: bold;
}
.custom-select {
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}
.big-card {
  height: 70vh;
  width: 30em;
  position: relative;
  box-shadow: 0 0 20px rgba(1, 1, 1, 0.16);
}

.box {
  height: 55%;
  width: 100%;
  border: 1px solid var(--blue);
}
.card-item {
  border: 1px solid var(--blue);
}
.theme-card {
  height: 10em;
  width: 20%;
  cursor: pointer;
}
.t-card-1 {
  background: url(../../assets/img/theme-light.png);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.t-card-2 {
  background: url(../../assets/img/theme-image.png);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.t-card-3 {
  background: url(../../assets/img/theme-dark.png);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.theme-shadow {
  transform: translateY(-3px) scale(1.01);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
}

.theme-card h6 {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-add-question {
  width: 15rem;
  height: 3.5rem !important;
  z-index: 100;
  background-color: var(--blue);
  font-size: 1.2rem;
  cursor: pointer;
}
.custom-publish-screen {
  height: 15vh !important;
  width: 100%;
  top: 10px;
  z-index: 100;
}
.custom-question-container {
  height: 75vh;
  overflow: auto;
  padding: 2rem 0;
}
.custom-button-publish {
  width: 15rem;
  height: 3.5rem !important;
  z-index: 100;
  font-size: 1.2rem;
  cursor: pointer;
}
.custom-add-option {
  height: 2rem;
  border-radius: 50% !important;
  width: 2rem;
  line-height: 0;
}
.custom-publish-bot {
  height: 3rem;

  color: #fff;
  width: 30rem;
}
.custom-cursor {
  cursor: no-drop !important;
}
.custom-modal {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(1, 1, 1, 0.46);
}
.custom-modal-card {
  height: 15rem;
  width: 30rem;
  background-color: #fff;
  border-radius: 1rem;
  margin-top: -10rem;
}
.custom-button-round {
  height: 5rem;
  width: 5rem;
  border: 4px solid black;
  margin-top: 3rem;
}
.custom-button-red {
  border: 4px solid var(--red);
  transition: 0.3s ease;
}
.custom-button-red:hover {
  background-color: var(--red);
  color: var(--white);
}
.custom-button-green {
  border: 4px solid var(--green);
  transition: 0.3s ease;
}
.custom-button-green:hover {
  background-color: var(--green);
  color: var(--white);
}
.custom-width-90 {
  width: 90%;
  margin: 0 auto;
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 11rem;
  height: 3rem;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--green);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.5rem;
  width: 2.5rem;
  left: 0.5rem;
  bottom: 0.25rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-slider:checked + .slider {
  background-color: var(--grayDark);
}

.custom-slider:focus + .slider {
  box-shadow: 0 0 1px var(--grayDark);
}

.custom-slider:checked + .slider:before {
  -webkit-transform: translateX(7.5rem);
  -ms-transform: translateX(7.5rem);
  transform: translateX(7.5rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 50rem;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-box-ne {
  /* background-color: var(--blue); */
  background-color: #eaf1fe;
  color: var(--dark);
  box-shadow: 0px 10px 20px rgba(1, 1, 1, 0.16);
}

.custom-addname-checkbox {
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--blue);
}
.custom-addname-checkbox:checked {
  background-color: var(--orange);
  border: 2px solid var(--orange);
}
.customDark {
  outline: 4px solid black;
}
</style>
