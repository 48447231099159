export const checkPrivateRouting =(path) =>{
    const localStorageVariable = localStorage.getItem('access_token')
    console.log(path)
    if( localStorageVariable){
        console.log('Is it in if?')
        return path
    }
    else{
        console.log('Is it in else?')
        return '/login'
    }


}