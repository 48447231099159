<template>
  <div class="flex justify-center">
    <img
      src="../../assets/animations/Rolling-1s-200px.gif"
      class="custom-loading"
      alt=""
    />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.custom-loading {
  height: 50%;
  width: auto;
}
</style>
