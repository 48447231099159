<template>
  <div class="h-screen flex flex-col justify-center items-center">
    <IndexNavbar />
    <div class="account_info relative h-full ">
      <div class="text-2xl font-bold py-2">My Profile</div>
      <div class="linegreen w-4/5 h-1"></div>
      <div class="user_info w-full container py-4">
        <div class="row flex justify-between mb-4">
          <div class="font-semibold ">User information</div>
          <button
            class="bg-blue-500 text-xs  text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          >
            Edit Profile
          </button>
        </div>
        <div
          class="user_container container w-4/5 mt-4 flex justify-between pb-4 "
        >
          <div class="user_profile flex justify-start">
            <img
              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
              class="user_image rounded-full mr-4"
              alt=""
            />
            <div>
              <div class="text-xs font-bold">User Name</div>
              <div class="mt-4" v-if="!profileDetails">Cassindra bla bla</div>
              <div class="mt-4" v-if="profileDetails">
                {{ profileDetails.first_name }} {{ profileDetails.last_name }}
              </div>
            </div>
          </div>
          <div>
            <div class="text-xs font-bold">Email Address</div>
            <div class="mt-4" v-if="!profileDetails">abc@efgh.com</div>
            <div class="mt-4" v-if="profileDetails">
              {{ profileDetails.email }}
            </div>
          </div>
        </div>
        <div class="lineblack"></div>
        <div class="contact_info h-full">
          <div class="font-semibold">
            Contact information
          </div>
          <div class="contact_info  h-full flex flex-wrap">
            <div class="contact_item">
              <div class="text-xs font-bold">Organization</div>
              <div class="mt-4" v-if="!profileDetails">Vouge</div>
              <div class="mt-4" v-if="profileDetails">
                {{ profileDetails.organisation }}
              </div>
            </div>
            <div class="contact_item">
              <div class="text-xs font-bold">Profile Status</div>

              <div class="mt-4" v-if="!profileDetails">SuperUser</div>
              <div
                class="mt-4"
                v-if="profileDetails && profileDetails.is_superuser"
              >
                Premium User
              </div>
              <div
                class="mt-4"
                v-if="profileDetails && !profileDetails.is_superuser"
              >
                Basic User
              </div>
            </div>
            <div class="contact_item">
              <div class="text-xs font-bold">Phone Number</div>
              <div class="mt-4" v-if="!profileDetails">+31 090 377 332</div>
              <div class="mt-4" v-if="profileDetails">
                {{ profileDetails.phone_number }}
              </div>
            </div>
            <div class="contact_item">
              <div class="text-xs font-bold">Date Joined</div>
              <div class="mt-4" v-if="!profileDetails">17/04/2019</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";

import { getProfileDetails } from "@/utils/api.js";

export default {
  data() {
    return {
      navbarOpen: false,
      profileDetails: {},
    };
  },
  methods: {
    setNavbarOpen: function() {
      this.navbarOpen = !this.navbarOpen;
    },

    async fetchProfileDetails() {
      const data = await getProfileDetails();
      console.log(data);
      this.profileDetails = data.profile;
    },
    checkUserPresence() {
      const user = localStorage.getItem("access_token");
      if (!user) {
        this.$router.push("/login");
      }
    },
  },
  mounted() {
    this.fetchProfileDetails();
  },
  created() {
    this.checkUserPresence();
  },
  components: {
    IndexNavbar,
  },
};
</script>

<style>
.bgGreen {
  background-color: #2ed47a;
}
.colGreen {
  color: #2ed47a;
}
</style>

<style scoped>
.account_info {
  width: 60%;
  height: 80%;
  /* border: 2px solid black; */
  margin-top: 2rem;
}
.linegreen {
  background-color: #2ed47a;
  height: 3px;
  width: 100%;
  margin: 1rem 0 1rem 0;
}
.lineblack {
  background-color: #333;
  height: 1px;
  width: 100%;
  margin: 1rem 0 1rem 0;
}
button {
  background-color: #2ed47a;
  color: white;
}
.user_image {
  width: 3rem;
  height: 3rem;
}
.user_container {
  width: 70%;
}
.contact_info {
  width: 100%;
}

.contact_info .contact_item {
  width: 50%;

  margin-top: 3rem;
}
</style>
