<template>
  <div class="h-screen custom-background">
    <IndexNavbar />
    <div class="custom-container flex justify-center items-center">
      <div class="custom-card bg-col-white p-4">
        <div class="text-4xl font-bold text-center color-dark mb-4">Login</div>
        <div>
          <div class="custom-input-fields">
            <input
              type="email"
              v-model="email"
              class="custom-input-field w-full "
              placeholder="Email"
              id="custom-input-email"
            />
          </div>
          <div class="custom-input-fields relative">
            <input
              :type="passwordType"
              v-model="password"
              class="custom-input-field w-full "
              placeholder="Password"
              id="custom-input-password"
            />
            <div
              class="custom-eye absolute cursor-pointer"
              @click="togglePasswordType"
            >
              <FontAwesomeIcon icon="eye" v-if="passwordType == `password`" />
              <FontAwesomeIcon
                icon="eye-slash"
                v-if="passwordType != `password`"
              />
            </div>
          </div>
          <div class="custom-checkbox flex items-center">
            <input
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
              v-model="rememberMe"
              @click="clickToToggleRememberMe"
            /><label for="rememberMe" class="font-semibold  ml-2"
              >Remember Me</label
            >
          </div>

          

          <div
            class="custom-login w-full bg-col-blue color-white text-center text-xl cursor-pointer rounded "
            @click="onLoginClick"
            v-if="!hasBeenSubmitted"
          >
            Login Now
          </div>

          <div
            class="custom-login-google w-full  text-center text-xl cursor-pointer rounded flex justify-center items-center "
            @click="useFirebaseGoogleSignup"
            v-if="!hasBeenSubmitted"
          >
            Login With 
            <img :src="GoogleLogo" alt="gl">
          </div>

          <div
            class="custom-login relative bg-col-blue color-white w-full flex justify-center items-center text-center text-xl cursor-pointer rounded"
            v-if="hasBeenSubmitted"
          >
            <Buttonloading class="custom-button" />
          </div>

          <div class="custom-signup py-2">
            Don't have an account?
            <router-link
              to="/registration"
              class="color-blue underline cursor-pointer"
              >Sign up here</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import whiteBg from "@/assets/img/white_bg.jpg";
import { logIn } from "@/utils/api.js";
import { getProfileDetails, loginByGoogle } from "@/utils/api.js";
import  GoogleLogo  from '@/assets/img/GoogleLogo.png';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Buttonloading from "../../components/Buttonloading/Buttonloading.vue";
library.add(faEye, faEyeSlash);

export default {
  data() {
    return {
      whiteBg,
      GoogleLogo,
      email: "",
      password: "",
      home: "",
      passwordType: "password",
      rememberMe: false,
      hasBeenSubmitted: false,
    };
  },
  methods: {
    async onLoginClick(e) {
      e.preventDefault();
      this.hasBeenSubmitted = true;
      //   console.log(this.$baseurl);
      this.home = "/landing";
      const data = await logIn(this.email, this.password);
      console.log(data);
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      if (data.access) {
        const profile = await getProfileDetails();
        console.log(profile.data);
      }
      if (data.access !== undefined) this.$router.push("/home");
      else if (data.access === undefined) {
        localStorage.clear();
        this.$router.push("/login");
        alert("Credentials galat hai be!");
        console.log("Credentials galat diye ho be!");
      }
    },
    clickToToggleRememberMe() {
      this.rememberMe = !this.rememberMe;
    },
    togglePasswordType() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    async useFirebaseGoogleSignup(){

      try{
    const data  = await loginByGoogle()
    if (typeof data.err !== undefined) {
        const name = data.displayName;
        const email = data.email;
        const phoneNumber = data.phoneNumber ? data.phoneNumber : '';
        console.log('Name :', name, ' Email:', email, " Phone Number:", phoneNumber);
        
        // const apiDetails = {
        //   name, 
        //   is_firebase : true,
        //   email,
        //   ph_no: phoneNumber,
          
        //   user_type:"owner",
        //   password : '',

        // }

        // console.log('Printing API deets here', apiDetails);
        // const resData = await userRegister(apiDetails);
        // console.log('ResData :', resData);
        //     if(resData.success){
        //       this.$router.push('/pages/newlogin')
        //       console.log('kaam ho raha hai!')

        //     }
        //     else{
        //       //User already exists

        //       //this.$router.go();
        //       console.log('kaam nahi ho raha hai!')
              
        //     }
      }
      else {
        window.location.reload();
      }
    }
    catch(err){
      console.log(err)
      console.log('This is the outside case');
    }


    }
  },
  watch: {
    email: function() {
      const element = document.querySelector("#custom-input-email");
      if (this.email) element.classList.add("custom-border-blue");
      else element.classList.remove("custom-border-blue");
    },
    password: function() {
      const element = document.querySelector("#custom-input-password");
      if (this.password) element.classList.add("custom-border-blue");
      else element.classList.remove("custom-border-blue");
    },
  },
  created() {},

  components: {
    IndexNavbar,
    FontAwesomeIcon,
    Buttonloading,
  },
};
</script>
<style scoped>
.custom-background {
  background: url(../../assets/img/dark_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.custom-container {
  height: calc(100% - 4rem);
}
.custom-card {
  min-height: 30em;
  min-width: 25em;
  border-radius: 1rem;
  box-shadow: 0 10px 20px -5px rgba(1, 1, 1, 0.16);
}
.custom-input-fields {
  margin: 3rem 0;
}
.custom-input-field {
  border: none;
  border-bottom: 3px solid var(--dark2);
  outline: none;
  transition: 0.4s ease;
  padding: 0.5em 0 0.5em 0;
  background-color: var(--white);
}
.custom-border-blue {
  border-bottom: 3px solid var(--blue) !important;
  transition: 0.4s ease;
}
.custom-login {
  padding: 0.5em 0;
  margin-top: 1rem;
  position: relative;
  height: 3rem;
}

.custom-login-google {
  box-shadow: 0px 5px 10px -1px rgba(1, 1, 1, 0.16);
  padding: 0.5em 0;
  margin-top: 1rem;
  position: relative;
  height: 3rem;
}

.custom-login-google img{
  height : 90%;
  width: auto;
  margin-left: 1rem;
}


.custom-button .custom-checkbox {
  margin-bottom: 2rem;
}
.custom-signup {
  margin-top: 1rem;
}
.custom-checkbox input {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.2rem;
}
.custom-checkbox label {
  font-size: 1.1rem;
}
.custom-eye {
  top: 20%;
  right: 2%;
  opacity: 0.7;
  transition: 0.3s ease;
}
.custom-eye:hover {
  opacity: 1;
}
.custom-button {
  height: 4rem;
  width: 4rem;
  position: relative;
  margin-top: 2rem;
}
</style>
