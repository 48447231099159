<template>
  <div>
    <IndexNavbar />
    <div class="custom-container py-12">
      <div class="text-3xl font-bold">Bot xy created</div>
      <div class="mt-12">
        <div
          class="custom-share text-xl font-semibold cursor-pointer inline-block relative p-2"
        >
          Share Link
        </div>
        <div class="custom-line w-full"></div>
        <div class="w-full pt-8 flex justify-between">
          <div class="text-xl color-blue font-semibold flex justify-center">
            <div class="mr-2">
              <FontAwesomeIcon icon="cloud-download-alt" />
            </div>
            <div>Direct Link</div>
          </div>
          <!-- <div>Survay Expires on 05/09/2021</div> -->
        </div>
        <div class="w-full py-2 text-sm">
          Copy this link to your clipboad and share it
        </div>
        <div class="py-8">
          <input
            type="text"
            disabled
            class="custom-input rounded bg-transparent outline-none"
            id="textToBeCopied"
            v-model="myLink"
          />
        </div>
        <button
          @click="copyToClipboard"
          class="custom-button bg-col-blue px-12 capitalize color-white rounded cursor-pointer mr-4 py-2 font-semibold"
        >
          Copy
        </button>
        <!-- <button
          @click="copyToClipboard"
          class="custom-button bg-col-white px-12 capitalize color-dark rounded cursor-pointer py-2 font-semibold"
        >
          Share
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faCloudDownloadAlt);
export default {
  data() {
    return {
      botId: "",
      myLink: "",
    };
  },
  methods: {
    copyToClipboard() {
      var copyText = document.querySelector("#textToBeCopied");
      copyText.select();
      document.execCommand("copy");
      navigator.clipboard.writeText(copyText.value);
      //alert("copied");
    },
    checkUserPresence() {
      const user = localStorage.getItem("access_token");
      if (!user) {
        this.$router.push("/login");
      }
    },
  },
  created() {
    this.checkUserPresence();
    console.log(this.$route.params.bot_id);
    this.botId = this.$route.params.bot_id;
    this.myLink = `https://app.formsbot.co/bot/${this.botId}`;
    console.log("THis is the link:", this.myLink);
  },
  components: {
    IndexNavbar,
    FontAwesomeIcon,
  },
};
</script>
<style scoped>
.custom-container {
  width: 80%;
  margin: 0 auto;
  height: calc(100vh - 4rem);
}
.custom-share::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--blue);
  bottom: 0;
  left: 0;
}
.custom-line {
  height: 1px;
  background-color: var(--dark2);
}
.custom-input {
  width: 20em;
  border: 3px solid var(--blue);
  outline: none;
  background: transparent;
}
.custom-input:focus,
.custom-input:active {
  outline: none;
}
.custom-button {
  border: 3px solid var(--blue);
  transition: 0.3s ease;
}
.custom-button:hover {
  background-color: var(--blue);
  color: #fff;
}
</style>
