import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const apiURL =
  "https://tbvchhqsdg.execute-api.ap-south-1.amazonaws.com/dev/api/";

// const urlNew =
//   "https://tbvchhqsdg.execute-api.ap-south-1.amazonaws.com/dev/api/token/refresh/";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      //this.$router.push("/login");
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${apiURL}token/refresh/`, {
          refresh: localStorage.getItem("refresh_token"),
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // 1) put token to LocalStorage
            localStorage.setToken("access_token", res.data.access);

            // 2) Change Authorization header
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("access_token");

            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

// const fakedata = {
//   first_name: "mayukh",
//   last_name: "1",
//   password: "12345",
//   phone_number: "1234567890",
//   organisation: "abc",
//   default_theme: "dark",
//   email: "mayukh@test.com",
// };

export const signUp = async (userRegestrationData) => {
  try {
    const newApi = `${apiURL}v1/signup/`;
    console.log(userRegestrationData);

    const res = await axios.post(newApi, {
      ...userRegestrationData,
    });
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const logIn = async (username, password) => {
  console.log(username, password);
  try {
    const newApi = `${apiURL}token/`;
    const res = await axios.post(newApi, { username, password });
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const postProject = async (title, description, start_date, end_date) => {
  try {
    const newApi = `${apiURL}v1/project/`;
    const res = await axios.post(newApi, {
      title,
      description,
      start_date,
      end_date,
    });
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getProfileDetails = async () => {
  try {
    const newApi = `${apiURL}v1/profile/`;
    console.log(newApi);
    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getProjectDetails = async () => {
  try {
    const newApi = `${apiURL}v1/project/`;
    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const postBot = async (
  title,
  project_id,
  description,
  intro_text,
  end_message,
  avatar_image,
  intro_image,
  background_img,
  theme,
  help_text,
  questions
) => {
  try {
    const newApi = `${apiURL}v1/bot/`;
    console.log(
      title,
      project_id,
      description,
      intro_text,
      end_message,
      avatar_image,
      intro_image,
      background_img,
      theme,
      help_text,
      questions
    );
    const res = await axios.post(newApi, {
      title,
      project_id,
      description,
      intro_text,
      end_message,
      avatar_image,
      intro_image,
      background_img,
      theme,
      help_text,
      questions,
    });
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const botInsights = async (offset, limit) => {
  const newApi = `${apiURL}v1/bots-insight/?offset=${offset}&limit=${limit}`;
  try {
    const res = await axios.get(newApi);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getBot = async (bot_id) => {
  try {
    const newApi = `${apiURL}v1/give-response/?bot_id=${bot_id}`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const sendBotConversation = async (conversation) => {
  try {
    const newApi = `${apiURL}v1/give-response/`;

    const res = await axios.post(newApi, conversation);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getQuestionByBotId = async (bot_id) => {
  try {
    const newApi = `${apiURL}v1/questions-insight/?bot_id=${bot_id}`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getQuestionById = async (question_id) => {
  try {
    const newApi = `${apiURL}v1/questions-insight/?question_id=${question_id}`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getQuestionResponsesById = async (question_id) => {
  try {
    const newApi = `${apiURL}v1/questions-insight/?question_id=${question_id}&&get_all_response=true`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getQuestionInsightsByBotId = async (bot_id) => {
  try {
    console.log(bot_id);
    const newApi = `${apiURL}v1/questions-insight/?bot_id=${bot_id}&&get_all_response=true`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getResponsesByUser = async (uuid) => {
  try {
    console.log(uuid);
    const newApi = `${apiURL}v1/questions-insight/?response_uuid=${uuid}&&get_all_response=true`;

    const res = await axios.get(newApi);
    console.log(res);
    return res.data;
  } catch (error) {
    return error;
  }
};


export const deleteBots = async(botId) =>{
  try{
    console.log(botId)
    const newApi = `${apiURL}v1/bot/`
    const res = await axios.delete(newApi, {bot_id : botId})
    console.log(res)
    return res.data
  }
  catch(error){
    return error;
  }
}



export const loginByGoogle = async() => {
  try{
    let provider = new firebase.auth.GoogleAuthProvider();
    let result = await firebase.auth().signInWithPopup(provider)

    var token = result.credential.accessToken
    
    var user = result.user
    console.log("This is Google login generated token", token)
    console.log(user)
    return user

    }
    catch(err){
        console.log(err)
        return ({err: {msg:'Cannot process request'}})
    } 
  
}
