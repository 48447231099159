<template>
  <div>
    <div class="flex justify-evenly p-4">
      <div>
        <div class="text-lg font-semibold color-dark">No of Questions</div>
        <div>100</div>
      </div>
      <div>
        <div class="text-lg font-semibold color-dark">No of Responses</div>
        <div>100</div>
      </div>
      <div>
        <div class="text-lg font-semibold color-dark">Date Submitted</div>
        <div>1/1/2029</div>
      </div>
    </div>
    <div class="flex justify-center mt-4" v-if="answerList.length == 0">
      <Loadingdata />
    </div>


    <div class="p-4" v-for="(item, index) in answerList" :key="index">
      <div class="flex py-2">
        <div class="font-semibold mr-4">{{ index + 1 }}.</div>
        <div class="color-dark">
          {{ item.question.question }}
        </div>
      </div>
      <div class="custom-padding-1">
        <div class="font-semibold " v-if="item.response.answer_text">
          {{ item.response.answer_text }}
        </div>
        <div
          class="font-semibold custom-option inline-block text-center mr-2 bg-col-blue color-white"
          v-if="item.response.option"
        >
          {{ item.response.option.option_text }}
        </div>
      </div>
    </div>
    <!-- {{ item.question.question }}

      
      {{ item.response.answer_text }} -->
  </div>
</template>

<script>
import { getResponsesByUser } from "@/utils/api.js";
import Loadingdata from "../../components/Loadingdata/Loadingdata.vue";

export default {
  data() {
    return {
      answerList: [],
    };
  },

  methods: {
    async fetchResponsesByUser() {
      const data = await getResponsesByUser(this.uuid);
      console.log(data);
      this.answerList = [...data.responses_data];
      console.log("This is answers list:", this.answerList);
    },
  },
  mounted() {
    this.fetchResponsesByUser();
  },
  watch:{
    uuid: function(){
      this.answerList = []
      this.fetchResponsesByUser()
    }
  },

  props: ["uuid"],
  components:{
    Loadingdata
  }
};
</script>

<style scoped>
.custom-padding-1 {
  padding-left: 1.5rem;
}
.custom-option {
  padding: 0.5rem 1rem;
  border-radius: 100rem;
  min-width: 4rem;

  border: 3px solid var(--blue);
}
</style>
