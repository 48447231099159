<template>
  <div class="bg-col-white h-screen">
    <div
      v-if="fakeData.length == 0"
      class="h-full flex justify-center items-center"
    >
      <PageLoading2 />
    </div>
    <div
      class="w-full h-screen flex justify-center"
      v-if="fakeData.length != 0"
    >
      <div class="custom-container-small h-full bg-col-blue color-white">
        <div class="custom-content-one flex justify-center items-center">
          <div class="custom-circle bg-col-white rounded-full"></div>
        </div>
        <div class="custom-content-two overflow-auto relative">
          <div class="custom-question flex items-center cursor-pointer">
            Question
          </div>
        </div>
        <div
          class="custom-content-three flex justify-evenly items-center flex-col"
        >
          <div class="text-xl font-semibold">
            Enjoyed taking the survey?
          </div>
          <router-link
            to="/createbot"
            class="custom-button font-semibold bg-col-blue rounded-full"
          >
            Create your own bot
          </router-link>
        </div>
      </div>
      <div class="custom-container-big h-full p-2">
        <div
          class="custom-question-number sticky w-full text-right p-2 color-white bg-col-dark rounded top-0"
          v-if="questionIndex !== fakeData[0].data.questions.length"
        >
          {{ questionIndex + 1 }} / {{ fakeData[0].data.questions.length }}
        </div>

        <div
          class="custom-question-number sticky w-full text-right p-2 color-white bg-col-dark rounded top-0"
          v-if="questionIndex === fakeData[0].data.questions.length"
        >
          {{ questionIndex }} / {{ fakeData[0].data.questions.length }}
        </div>

        <!-- The conversation container -->
        <div class="custom-conversation-container p-2">
          <div
            class="custom-bot-intro-response color-dark p-4"
            v-if="botIntroText"
          >
            {{ botIntroText }}
          </div>
          <!-- Loading previous conversations -->
          <div :key="index" v-for="(con, index) in conversation">
            <div
              class="custom-response-container w-full relative my-4 flex justify-center items-start flex-col"
            >
              <div class="custom-bot-response color-dark p-4">
                {{ con.question.question }}
              </div>

              <div class="custom-options flex justify-start">
                <div
                  class="custom-option-question color-blue mr-2 cursor-pointer mt-2"
                  v-for="(option, index) in con.question.options"
                  :key="index"
                >
                  {{ option.option_text }}
                </div>
              </div>
              <div class="custom-options flex justify-evenly"></div>
            </div>

            <div
              class="custom-response-container w-full relative my-4 flex justify-center items-end flex-col"
            >
              <div
                class="w-full relative flex justify-end"
                v-if="con.question.type == 1 || con.question.type == 2"
              >
                <div
                  class="custom-human-response bg-col-green color-white p-4 "
                  v-for="(answer, index) in con.answer"
                  :key="index"
                >
                  {{ answer }}
                </div>
              </div>

              <div
                class="w-full relative flex justify-end"
                v-if="con.question.type == 3 || con.question.type == 4"
              >
                <div class="custom-human-response bg-col-green color-white p-4">
                  {{ con.answer }}
                </div>
              </div>
            </div>
          </div>
          <!-- Loading previous conversations -->

          <!-- Options type questions -->
          <div
            class="custom-response-container my-4 flex justify-center items-start flex-col"
          >
            <!-- The question container -->
            <div
              class="custom-bot-response custom-bot-animation color-dark p-4"
            >
              {{ currentQuestion.question }}
            </div>
            <!-- The question container -->

            <!-- Single Type MCQS -->
            <div
              class="custom-options flex justify-start"
              v-if="currentQuestion.type == 1"
            >
              <div
                class="custom-option-question color-blue mr-2 cursor-pointer mt-2"
                v-for="(option, index) in currentQuestion.options"
                :key="index"
                @click="selectSingleOption($event, option)"
              >
                {{ option.option_text }}
              </div>
            </div>
            <!-- Single Type MCQS -->
            <!-- Double Type MCQS -->
            <div
              class="custom-options flex justify-start"
              v-if="currentQuestion.type == 2"
            >
              <div
                class="custom-option-question color-blue mr-2 cursor-pointer mt-2"
                v-for="(option, index) in currentQuestion.options"
                :key="index"
                @click="selectMultipleOption($event, option)"
              >
                {{ option.option_text }}
              </div>
            </div>

            <!-- Double Type MCQS -->
          </div>

          <!-- Options type questions -->
        </div>
        <!-- The conversation container -->

        <!-- If conversation does not end then answer box is this -->
        <div class="custom-answer-container relative" v-if="!conversationEnded">
          <FontAwesomeIcon
            icon="paper-plane"
            class="custom-paper-plane cursor-pointer color-white absolute"
            @click="giveAnswer"
          />
          <input
            type="text"
            class="custom-answer-bar w-full text-left p-2 color-white bg-col-blue px-4 pr-12"
            placeholder="Write here ..."
            v-model="currentAnswer"
          />
        </div>

        <!-- If conversation does not end then answer box is this -->

        <!-- If conversation  ends then answer box is this -->

        <div class="custom-answer-container relative" v-if="conversationEnded">
          <FontAwesomeIcon
            icon="paper-plane"
            class="custom-paper-plane cursor-pointer color-white absolute"
            @click="giveAnswer"
          />
          <input
            type="text"
            class="custom-answer-bar w-full text-left p-2 color-white bg-col-blue px-4 pr-12"
            placeholder="Write here ..."
            v-model="currentAnswer"
            disabled
          />
        </div>

        <!-- If conversation  end then answer box is this -->
      </div>

      <!-- Conversation end screen -->
      <div
        class="custom-thank-you h-screen absolute w-full"
        v-if="conversationEnded"
      >
        <div
          class="custom-glass-card absolute p-4 flex justify-evenly items-center flex-col"
        >
          <div class="text-4xl font-semibold text-center color-white">
            Thank you for taking the survey
          </div>
          <div class="text-3xl font-medium color-white">
            Enjoyed the survey?
          </div>
          <router-link
            to="/createbot"
            class="custom-button-close color-white rounded-full bg-col-blue flex justify-center items-center text-xl"
          >
            Create a new bot
          </router-link>
        </div>
        <div class="custom-circle-big absolute"></div>
        <div class="custom-circle-small absolute"></div>
      </div>

      <!-- Conversation end screen -->
    </div>
  </div>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { getBot, sendBotConversation } from "@/utils/api.js";
import { faEye, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import PageLoading1 from "../../components/PageLoading1/PageLoading1.vue";
import PageLoading2 from "../../components/PageLoading2/PageLoading2.vue";

//import fakeData from "./FakeData.json";
library.add(faEye, faPaperPlane);
export default {
  data() {
    return {
      fakeData: [],
      newData: "First Question",
      conversation: [],
      questionList: [],
      questionIndex: 0,
      currentAnswer: [],
      currentMcqAnswer: [],
      conversationEnded: false,
      optionKeeper: [],
      botUuid: "",
      conversationForApi: {
        bot_uuid: "",
        responses: [],
      },
      botIntroText: "",

      individualAnswer: "",
    };
  },
  methods: {
    selectSingleOption(e, option) {
      console.log(option);

      this.currentAnswer = [option.option_text];
      this.optionKeeper = [option.id];
      console.log("Option Number selected :", this.optionKeeper);

      console.log(this.currentAnswer);
    },

    selectMultipleOption(e, option) {
      //console.log(e.target)
      e.target.classList.toggle("active");
      if (this.currentMcqAnswer.includes(e.target.innerHTML)) {
        const index = this.currentMcqAnswer.indexOf(e.target.innerHTML);
        this.currentMcqAnswer.splice(index, 1);

        const indexOption = this.optionKeeper.indexOf(option.id);
        this.optionKeeper.splice(indexOption, 1);
      } else {
        this.currentMcqAnswer.push(e.target.innerHTML);
        this.optionKeeper.push(option.id);
      }
      console.log(this.currentMcqAnswer, "Option Keeper:", this.optionKeeper);
      //console.log(e.target.classList);
    },
    giveAnswer() {
      if (this.fakeData[0].data.questions.length > this.questionIndex) {
        console.log(
          "yeh question object hai",
          this.fakeData[0].data.questions[this.questionIndex]
        );
        let chat = {};
        let chatForApi = {};

        // For Multiple MCQS
        if (this.currentQuestion.type === 2) {
          console.log("Entering the MCQ portion of Give answer");
          chat = {
            question: this.currentQuestion,
            answer: this.currentMcqAnswer,
          };

          chatForApi = {
            question_id: this.currentQuestion.id,
            response_text: "",
            option_id: [...this.optionKeeper],
          };
        }
        // For Single MCQs
        else if (this.currentQuestion.type === 1) {
          chat = {
            question: this.currentQuestion,
            answer: this.currentAnswer,
          };

          chatForApi = {
            question_id: this.currentQuestion.id,
            response_text: "",
            option_id: [...this.optionKeeper],
          };
        }

        // For long and short type of answers
        else {
          console.log(
            "the current Long format Question is:",
            this.currentQuestion
          );
          console.log("Answer hai:", this.currentAnswer);
          chat = {
            question: this.currentQuestion,
            answer: this.currentAnswer,
          };

          chatForApi = {
            question_id: this.currentQuestion.id,
            response_text: this.currentAnswer,
            option_id: [],
          };
        }
        console.log("yeh chat hai:", chat);
        this.conversation.push(chat);
        this.conversationForApi.bot_uuid = this.botUuid;
        this.conversationForApi.responses.push(chatForApi);
        //console.log(this.conversation);
        console.log("Conversation for API:", this.conversationForApi);
        this.optionKeeper = [];
        this.questionList.push(
          this.fakeData[0].data.questions[this.questionIndex]
        );
        this.currentAnswer = "";

        this.questionIndex += 1;
      }

      console.log(this.currentQuestion);
    },

    async loadQuestions() {
      const data = await getBot(this.botUuid);
      //console.log("The questions received from API call",data)
      //console.log('Entering here!')
      const newData = data;
      this.fakeData.push(newData);
      console.log("Fake data contents", this.fakeData);
      this.botUuid = this.fakeData[0].data.bot.uid;
      this.botIntroText = this.fakeData[0].data.bot.intro_text;
      console.log("This is the bot UUID", this.botUuid);
    },

    async postConversationToApi() {
      const data = await sendBotConversation(this.conversationForApi);
      console.log("Data submitted succesfully:", data);
    },
  },

  mounted() {
    this.loadQuestions();
  },

  watch: {
    questionIndex: function() {
      if (this.conversation.length == this.fakeData[0].data.questions.length) {
        this.postConversationToApi();
        setTimeout(() => {
          this.conversationEnded = true;
        }, 2000);

        console.log(this.conversationEnded);
      }

      console.log(this.conversationEnded);
    },

    conversation : { handler(){
      var scrollingElement = (document.querySelector('.custom-conversation-container') || document.body);
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    },
    deep : true,
    }

  },

  computed: {
    currentQuestion: function() {
      if (this.fakeData[0].data.questions.length > this.questionIndex)
        return this.fakeData[0].data.questions[this.questionIndex];
      else return { question: "Thank you for taking the survey. :)" };
    },
  },

  created() {
    //console.log('The router parameter is:', this.$route.params.bot_id)
    this.botUuid = this.$route.params.bot_id;
    console.log("Bot UUID is:", this.botUuid);
  },
  components: {
    FontAwesomeIcon,

    PageLoading2,
  },
};
</script>

<style scoped>
.custom-container-small {
  width: 25%;
  background-color: #363740;
}
.custom-container-big {
  width: 75%;
}
.custom-content-one {
  height: 25%;
}
.custom-content-two {
  height: 50%;
}
.custom-content-three {
  height: 25%;
}

.custom-content-two {
  scrollbar-width: auto;
  scrollbar-color: #5d5b5d98 #ffffff;
}

.custom-content-two::-webkit-scrollbar {
  width: 8px;
}

.custom-content-two::-webkit-scrollbar-track {
  background: transparent;
}

.custom-content-two::-webkit-scrollbar-thumb {
  background-color: #5d5b5d98;
  border-radius: 10px;
  border: 1px solid #ffffff24;
}
.custom-question {
  position: sticky;
  height: 3rem;
  top: 0px;
  width: 100%;
  padding: 0 2rem;
  border-left: 3px solid var(--white);
  background-color: #4a4b53;
}
.custom-circle {
  width: 4em;
  height: 4em;
}
.custom-button {
  padding: 1em 2em;
}
.custom-question-number {
  max-height: 2.5em;
}

.custom-answer-container {
  height: 2.5em;
  position: relative;
}

.custom-conversation-container {
  overflow: auto;
  scrollbar-width: auto;
  scrollbar-color: #a5a5a798 #ffffff;
  height: calc(100% - 6em);
  padding: 1rem;
}

.custom-conversation-container::-webkit-scrollbar {
  width: 8px;
}

.custom-conversation-container::-webkit-scrollbar-track {
  background: transparent;
}

.custom-conversation-container::-webkit-scrollbar-thumb {
  background-color: #cac6ca98;
  border-radius: 10px;
  border: 1px solid #ffffff24;
}
.custom-answer-bar {
  height: 3em;
  background-color: #b0befd;
  border-radius: 1rem;
  box-shadow: 0px 10px 20px rgba(1, 1, 1, 0.2);
  border: none;
  transition: 0.5s ease;
  outline: none;
}
.custom-answer-bar:active,
.custom-answer-bar:focus {
  background-color: #6e87fd;
  box-shadow: 0px 10px 25px rgba(1, 1, 1, 0.25);
}
.custom-answer-bar::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.custom-paper-plane {
  right: 2%;
  top: 50%;
  transform: translate(0%, -40%);
  font-size: 1.5rem;
}
.custom-bot-response {
  min-width: 3em;
  max-width: 60%;
  border-radius: 1.8rem 1.8rem 1.8rem 5px;
  box-shadow: 0px 5px 10px -2px rgba(1, 1, 1, 0.13);
  background-color: #eaf1fe;
  margin-bottom: 1rem;
  transition: 0.3s ease;

  /* transform-origin: bottom left; */
}

.custom-bot-intro-response {
  display: inline-block;
  min-width: 3em;
  border-radius: 1.8rem 1.8rem 1.8rem 5px;
  box-shadow: 0px 5px 10px -2px rgba(1, 1, 1, 0.13);
  background-color: #eaf1fe;
  margin-bottom: 1rem;
  transition: 0.3s ease;

  /* transform-origin: bottom left; */
}
.custom-bot-animation {
  animation: botchat 5s infinite;
}

@keyframes botchat {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.custom-human-response {
  min-width: 3em;
  max-width: 60%;
  border-radius: 1.8rem 1.8rem 5px 1.8rem;
  box-shadow: 0px 5px 10px -2px rgba(1, 1, 1, 0.13);
  background-color: var(--blue);
  margin-bottom: 1rem;
}
.custom-options {
  max-width: 60%;
  flex-wrap: wrap;
}
.custom-option-question {
  padding: 0.5em 1em;
  border-radius: 10rem;
  border: 2px solid var(--blue);
}
.custom-option-question.active {
  padding: 0.5em 1em;
  border-radius: 10rem;
  background: var(--blue) !important;
  color: var(--white);
  border: 2px solid var(--blue);
}
.custom-thank-you {
  background: rgb(159, 137, 222);
  background: radial-gradient(
    circle,
    rgba(159, 137, 222, 1) 0%,
    rgba(90, 245, 217, 1) 100%
  );
  background-size: 400%;
  background-position-x: 25%;
  background-position-y: 10%;
  rotate: 90deg;
  overflow: hidden;
  animation: animatingGradient 20s ease infinite alternate;
}
.custom-glass-card {
  height: 27em;
  width: 45em;

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(80, 223, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.custom-circle-big {
  min-height: 50rem;
  min-width: 50rem;
  top: 30%;
  left: -10%;
  background-color: #b7beff;
  border-radius: 50%;
}
.custom-circle-small {
  min-height: 20rem;
  min-width: 20rem;
  top: 3%;
  right: 13%;
  background-color: orange;
  border-radius: 50%;
}
@keyframes animatingGradient {
  0% {
    background-position-x: 25%;
    background-position-y: 10%;
  }
  100% {
    background-position-x: 100%;
    background-position-y: 10%;
  }
}
.custom-button-close {
  min-height: 4rem;
  min-width: 17rem;
  cursor: pointer;
  box-shadow: 0px 10px 30px rgba(54, 123, 245, 0.5);
}
.custom-response-container {
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .custom-container-small {
    display: none;
  }
  .custom-container-big {
    width: 100%;
  }
  .custom-bot-response {
    max-width: 90%;
    font-size: 14px;
    padding-bottom: 0.45rem;
    padding-top: 0.45rem;
  }
  .custom-options {
    max-width: 95%;
  }
  .custom-option-question {
    font-size: 14px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .custom-human-response {
    max-width: 90%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    font-size: 14px;
  }
  .custom-bot-intro-response {
    font-size: 14px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .custom-question-number {
    font-size: 14px;
  }
  .custom-answer-bar::placeholder {
    font-size: 14px;
  }
  .custom-glass-card {
    height: 35em;
    width: 20em;
  }
}
</style>
