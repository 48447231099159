<template>
  <nav
    class="top-0 sticky z-50 w-full flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg shadow text-white bgPrimary"
  >
    <div
      class="w-full px-6 mx-auto flex flex-wrap items-center justify-between "
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link to="/">
          <a
            class="text-3xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
            href="#pablo"
          >
            <img :src="img" alt="none" />
          </a>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto ">
          
          <li class="flex items-center px-3">
            <router-link to="/home" class="text-sm">
              Home
            </router-link>
          </li>
          <li class="flex items-center px-3">
            <router-link to="/manage" class="text-sm">
              Manage
            </router-link>
          </li>
          <li class="flex items-center px-3">
            <router-link to="/profile" class="text-sm">
              Settings
            </router-link>
          </li>
          <li class="flex items-center px-3" v-if="!accessToken">
            <router-link to="/login" class="text-sm">
              Login
            </router-link>
          </li>
          <li class="flex items-center px-3" v-if="accessToken">
            <router-link to="#" class="text-sm" @click="clearLocalStorage">
              Logout
            </router-link>
          </li>
          <li class="flex items-center px-3" v-if="!accessToken">
            <router-link to="/registration" class="text-sm">
              Sign up
            </router-link>
          </li>
          <li class="flex items-center px-3">
            <router-link to="/profile" class="text-sm">
              <div class="flex flex-wrap justify-center">
                <div class="w-6/12 sm:w-4/12 px-1">
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/unilite-shift-human-vol-1/60/011_001_profile_avatar_round_user-512.png"
                    alt="..."
                    class="rounded-full h-auto w-16"
                  />
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
.bgPrimary {
  background-color: #367bf5;
}
.colPrimary {
  color: #367bf5;
}
</style>

<script>

import logo from "../../assets/img/Formsbot..png";

export default {
  data() {
    return {
      navbarOpen: false,
      img: logo,
      accessToken: false,
    };
  },
  methods: {
    setNavbarOpen: function() {
      this.navbarOpen = !this.navbarOpen;
    },
    checkLogin() {
      let access = localStorage.getItem("access_token");
      if (access) {
        this.accessToken = true;
      } else {
        this.accessToken = false;
      }
    },
    clearLocalStorage() {
      localStorage.clear();
      this.$router.push("/home");
      location.reload(true);
    },
  },
  created() {
    this.checkLogin();
  },

  
};
</script>
<style scoped>
nav {
  max-height: 4rem;
}
</style>
