<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
:root {
  --blue: #367bf5;
  --red: #ff5756;
  --gray: #e5e5e5;
  --dark2: #363740;
  --white: #f4f6fd;
  --green: #2fa84f;
  --grayDark: #a8a8a8;
  --orange: #fb8832;
}

.color-red {
  color: #ff5756;
}
.color-blue {
  color: #367bf5;
}
.color-green {
  color: #2fa84f;
}
.color-gold {
  color: #f3aa18;
}
.color-orange {
  color: #fb8832;
}
.color-white {
  color: #f4f6fd;
}
.color-dark-2 {
  color: #363740;
}
.color-gray {
  color: var(--gray);
}
.color-grayDark {
  color: var(--grayDark);
}
.bg-col-blue {
  background-color: #367bf5;
}
.bg-col-red {
  background-color: #ff5756;
}
.bg-col-green {
  background-color: #2fa84f;
}

.bg-col-gold {
  background-color: #f3aa18;
}
.bg-col-orange {
  background-color: #fb8832;
}
.bg-col-blue2 {
  background-color: #2c95ac;
}

.bg-col-dark {
  background-color: #363740;
}
.bg-col-white {
  background-color: #f4f6fd;
}
.bg-col-dark-2 {
  background-color: #363740;
}
.bg-col-gray {
  background-color: var(--gray);
}
.bg-col-grayDark {
  background-color: var(--grayDark);
}
.btn-create-sm {
  width: 10rem;
  height: 3rem;
}
.font-small {
  font-size: 0.8rem;
}

.shadow-sm-blue {
  box-shadow: 0 5px 20px -10px rgba(54, 123, 245, 0.6);
}
.shadow-md-blue {
  box-shadow: 0 5px 20px -5px rgba(54, 123, 245, 0.6);
}
.shadow-lg-blue {
  box-shadow: 0 5px 20px 0px rgba(54, 123, 245, 0.6);
}
@media only screen and(max-width:500px) {
  html,
  body {
    font-size: 10px !important;
  }
}
</style>
