<template>
  <div>
    <IndexNavbar />
    <div
      class="flex flex-row justify-between items-center py-2 px-2 bg-col-dark"
    >
      <div
        class="color-white cursor-pointer"
        v-if="dataClicked"
        @click="setClickFalse"
      >
        <span class="color-blue text-2xl">&#8592;</span> Back to previous
      </div>
      <router-link
        class="color-white cursor-pointer"
        v-if="!dataClicked"
        to="/home"
      >
        <span class="color-blue text-2xl">&#8592;</span> Back to previous
      </router-link>
      <div class="py-2 flex">
        <div v-if="dataClicked">
          <input
            class="search_item"
            placeholder="Search"
            type="text"
            v-model="searchText"
          />
          <div
            class="custom-search-results"
            v-if="searchResults.length > 0 && searchText"
          >
            <div
              v-for="(item, index) in searchResults"
              :key="index"
              @click="openAllResponses(item)"
              class="cursor-pointer"
            >
              {{ item.question }}
            </div>
          </div>
        </div>
      </div>
      <router-link
        to="/createbot"
        class="blueShadow btn-create-sm px-2  bg-white shadow rounded-full manageBotLink text-lg font-semibold flex justify-center items-center"
      >
        <div
          class="blue flex justify-center items-center mr-1  rounded-full text-white plusSign"
        >
          +
        </div>
        <div class="color-blue font-small">Create a New Bot</div>
      </router-link>
    </div>

    <!--  Static data starts here -->

    <!-- <div class="flex flex-row justify-evenly py-8">
      <div class="flex flex-col">
        <div>Total Responses</div>
        <div class="text-4xl font-semibold color-red">
          123
        </div>
      </div>
      <div class="flex flex-col">
        <div>Survays Completed</div>
        <div class="text-4xl font-semibold color-blue">
          123
        </div>
      </div>
      <div class="flex flex-col">
        <div>Pending</div>
        <div class="text-4xl font-semibold color-green">
          123
        </div>
      </div>
      <div class="flex flex-col">
        <div>Response Rate</div>
        <div class="text-4xl font-semibold color-gold">
          12%
        </div>
      </div>
    </div> -->

    <!--  Static data ends here -->

    <div class="manageContainer w-full" v-if="!dataClicked">
      <div class="px-12">
        <div class="flex flex-row py-2">
          <div class="filterStyle custom-expand-icon">Filters</div>
          <div>
            <select
              name="cars"
              id="cars"
              class="project custom-expand-icon "
              v-model="selectedProject"
              v-if="projects"
            >
              <option
                v-for="project in projects"
                :value="project"
                :key="project.id"
                >{{ project.title }}</option
              >
            </select>
          </div>

          <div class="exportStyle custom-expand-icon">
            Export <img :src="downloadImage" alt="" />
          </div>
        </div>

        <div class="w-full py-1 cardBackground rounded">
          <table class="w-full">
            <thead>
              <tr>
                <th class="py-1 my-2 border-b-2 px-2 border-black text-left">
                  Bot Name
                </th>
                <th class="py-3 my-2  border-b-2 px-2 border-black text-left">
                  No Of Questions
                </th>
                <th class="py-3 my-2 border-b-2 px-2 border-black text-left">
                  No Of Responses
                </th>
                <th class="py-3 my-2 border-b-2 px-2 border-black text-left">
                  Project Name
                </th>
                <th class="py-3 my-2 border-b-2 px-2 border-black text-left">
                  Actions
                </th>
              </tr>
            </thead>

            

            <tbody>
              <tr
                v-for="data in realData"
                :key="data.id"
                class="cursor-pointer tableData"
                
              >
                <td class="text-left px-2 py-2 border-t border-black" @click="setClickTrue(data)">
                  {{ data.title }}
                </td>
                <td class="text-left px-2 py-2 border-t border-black" @click="setClickTrue(data)">
                  {{ data.total_questions }}
                </td>
                <td class="text-left px-2 py-2 border-t border-black" @click="setClickTrue(data)">
                  {{ data.total_responses }}
                </td>
                <td class="text-left px-2 py-2 border-t border-black" @click="setClickTrue(data)">
                  {{ data.project }}
                </td>
                <td class="text-left px-2 py-2 border-t border-black">
                  <button
                    class="btn-view  text-white font-semibold py-2 px-4 rounded bg-col-red"
                     @click="deleteUserBot(data.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div
          class="flex justify-center items-center relative"
          v-if="realData.length == 0"
        >
          <Loadingdata />
        </div>
        </div>
        

        <div class="custom-pagination flex between my-4 justify-center">
          <div class="custom-pagination-page">
            <button @click="previousPageDisplayBots">&lt;</button>
            <input type="text" :value="currentPageBots" />
            <button @click="nextPageDisplayBots">&gt;</button>
          </div>
          <div class="custom-pagination-data flex items-center">
            <div>Showing</div>
            <input type="number" min="1" class="pagination" v-model="botsLimit" />
            <div>items per page</div>
          </div>
        </div>
      </div>

      <!-- data clicked -->
    </div>

    <div class="w-full h-full" v-if="dataClicked">
      <div class="flex h-full">
        <div class="bot_container pt-2" v-if="botQuestionPage">
          <div class="flex flex-row items-center pb-4">
            <div
              class="font-semibold text-xl color-blue"
              style="margin-right:3rem;"
            >
              {{ currentBotName }}
            </div>
            <div class="px-2 py-2 border-three font-bold">
              Questions
            </div>
            <div class="px-2 py-2 cursor-pointer " @click="getQuestionsPage()">
              Insights
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <div>Questions</div>
            <div class="flex flex-row">
              <div
                class="mr-2 bg-col-gray px-2 py-1 cursor-pointer"
                @click="downloadCsv"
              >
                Download
              </div>
              <div class="bg-col-gray px-2 py-1 cursor-pointer">Share</div>
            </div>
          </div>
          <!-- This is the Questions page of Bots-Insight Questions  -->

          <table class="question_table w-full my-2">
            <thead>
              <th class="border-b text-left py-3">ID</th>
              <th class="border-b text-left py-3">Questions</th>
              <th class="border-b text-left py-3">Responses</th>

              <th class="border-b text-left py-3">Actions</th>
            </thead>
            <tbody>
              <tr
                class="tablerow cursor-pointer"
                v-for="i in fakeOfFakeQuestions"
                :key="i"
                @click="openAllResponses(i)"
              >
                <td class="text-left py-1 t-data">#213F{{ i.id }}</td>
                <td class="text-left py-1 t-data  cursor-pointer">
                  {{ i.question }}
                </td>
                <td class="text-left py-1 t-data">{{ i.response_count }}</td>

                <td class="text-left py-1 t-data">
                  <button
                    class="btn-view  text-white font-semibold py-2 px-4 rounded bg-col-red"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex justify-center items-center relative"
            v-if="fakeOfFakeQuestions.length == 0"
          >
            <Loadingdata />
          </div>

          <!-- This is theend ing of the Questions page of Bots-Insight Questions  -->
          <div class="custom-pagination flex between my-4 justify-center">
            <div class="custom-pagination-page">
              <button @click="previousPageDisplayQuestions">&lt;</button>
              <input type="text" :value="currentPageQuestions" />
              <button @click="nextPageDisplayQuestions">&gt;</button>
            </div>
            <div class="custom-pagination-data flex items-center">
              <div>Showing</div>
              <input type="text" v-model="numberOfQuestionsPerPage" />
              <div>items per page</div>
            </div>
          </div>
        </div>
        <div class="bot_container pt-2" v-if="botResponsePage">
          <div class="flex flex-row items-center pb-4">
            <div
              class="font-semibold text-xl color-blue"
              style="margin-right:3rem;"
            >
              {{ currentBotName }}
            </div>
            <div
              class="px-2 py-2 cursor-pointer"
              @click="
                botResponsePage = false;
                botQuestionPage = true;
              "
            >
              Questions
            </div>
            <div class="px-2 py-2 border-three  font-bold">Insights</div>
          </div>
          <div class="flex flex-row justify-between">
            <div>Questions</div>
            <div class="flex flex-row">
              <div
                class="mr-2 bg-col-gray px-2 py-1 cursor-pointer"
                @click="downloadCsvForInsights"
              >
                Download
              </div>
              <div class="bg-col-gray px-2 py-1 cursor-pointer">Share</div>
            </div>
          </div>

          <!-- This is the insights page of Bot Sights Questions Page -->
          <table class="question_table w-full my-2">
            <thead>
              <th class="border-b text-left py-3">ID</th>
              <th class="border-b text-left py-3">Unique ID</th>
              <th class="border-b text-left py-3">Questions Answered</th>
              <th class="border-b text-left py-3">Date</th>
              <th class="border-b text-left py-3">Actions</th>
            </thead>
            <tbody>
              <tr
                class="tablerow cursor-pointer"
                v-for="(i, index) in userResponsesToBot"
                :key="index"
                @click="openUserResponses(i.uid)"
              >
                <td class="text-left py-1 t-data">{{ index + 1 }}</td>
                <td class="text-left py-1 t-data  cursor-pointer">
                  {{ i.uid.substring(0, 11) }}...
                </td>
                <td class="text-left py-1 t-data">{{ i.count }}</td>
                <td class="text-left py-1 t-data">23/11/2012(static)</td>

                <td class="text-left py-1 t-data">
                  <button
                    class="btn-view  text-white font-semibold py-2 px-4 rounded bg-col-red"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="userResponsesToBot.length == 0 && !noResponsesFound"
            class="flex justify-center"
          >
            <Loadingdata />
          </div>
          <div
            v-if="noResponsesFound"
            class="flex justify-center text-3xl font-bold"
          >
            No Responses Found !!!
          </div>

          <div
            v-show="openIndividualUserResponse"
            class="custom-responses-user fixed h-full bg-col-white"
          >
            <div
              class="w-full h-12 bg-col-dark flex justify-end p-2 px-4 items-center"
            >
              <div
                class="color-white cursor-pointer"
                @click="closeUserResponses"
              >
                &#10005;
              </div>
            </div>

            <ResponsesByUser :uuid="currentUid" />
          </div>

          <!-- This is the end of the insights page of Bot Sights Questions Page -->

          <div class="custom-pagination flex between my-4 justify-center">
            <div class="custom-pagination-page">
              <button @click="previousPageDisplayQuestions">&lt;</button>
              <input type="text" :value="currentPageQuestions" />
              <button @click="nextPageDisplayQuestions">&gt;</button>
            </div>
            <div class="custom-pagination-data flex items-center">
              <div>Showing</div>
              <input type="text" v-model="numberOfQuestionsPerPage" />
              <div>items per page</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- This is the Question Info Details page -->

    <div
      class="custom-responses fixed h-full bg-col-white"
      v-show="openResponses"
    >
      <div
        class="w-full h-12 bg-col-dark flex justify-end p-2 px-4 items-center"
        v-if="Object.keys(fakeQuestionDetails).length != 0"
      >
        <div class="color-white cursor-pointer" @click="closeAllResponses">
          &#10005;
        </div>
      </div>
      <div class="text-xl my-2 p-2">Response Info</div>
      <div class="flex justify-between mb-4 p-2">
        <div>
          <div class="text-lg">Question Type</div>
          <div class="text-sm" v-if="fakeQuestionDetails.question.type == 1">
            Single Answer MCQ
          </div>

          <div class="text-sm" v-if="fakeQuestionDetails.question.type == 2">
            Multiple Answer MCQ
          </div>

          <div class="text-sm" v-if="fakeQuestionDetails.question.type == 3">
            Short answer
          </div>

          <div class="text-sm" v-if="fakeQuestionDetails.question.type == 4">
            Long Answer
          </div>
        </div>
        <div>
          <div class="text-lg">No of Responses</div>
          <div class="text-sm">
            {{ fakeQuestionDetails.responses_data.length }}
          </div>
        </div>
        <div>
          <div class="text-lg">Date submitted</div>
          <div class="text-sm">
            21/31/43 (static)
          </div>
        </div>
        <div></div>
        <div></div>
      </div>

      <table class="custom-question-response-table">
        <tr class="custom-question-response-heading">
          <th class="custom-width-33">ID</th>
          <th class="custom-width-33">Responses</th>
          <th class="custom-width-33">Action</th>
        </tr>

        <tr
          class="custom-question-response-data"
          v-for="(question, index) in questionDetails"
          :key="index"
        >
          <td
            class="custom-width-33"
            @click="navigateToIndividualResponsePage(question.uid)"
          >
            {{ question.uid.substring(0, 11) }}...
          </td>
          
          <td class="custom-width-33" v-if="question.option">
            {{ question.option.option_text }}
          </td>
          <td class="custom-width-33" v-if="!question.option">
            {{ question.answer_text }}
          </td>



          <td class="custom-width-33">
            <button class="bg-col-red color-white rounded px-2 py-1">
              Delete
            </button>
          </td>
        </tr>
      </table>

      <div v-if="questionDetails.length == 0">
        <Loadingdata />
      </div>
    </div>

    <!-- This is end of the the Question Info Details page -->
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import ResponsesByUser from "@/components/ResponsesByUser/ResponsesByUser.vue";
import img from "../../assets/img/download.png";
import { getProjectDetails } from "@/utils/api.js";
import {
  botInsights,
  getQuestionByBotId,
  getQuestionById,
  getQuestionInsightsByBotId,
  deleteBots

} from "@/utils/api.js";

import fakeQuestionDetails from "./FakeQuestionDetails.json";
import Loadingdata from "../../components/Loadingdata/Loadingdata.vue";


// const axios = require("axios");

export default {
  data() {
    return {
      
      numberOfQuestionsPerPage: 5,
      fixedNumberOfData: 5,
      navbarOpen: false,
      dataClicked: false,
      openResponses: false,
      openIndividualUserResponse: false,
      currentUid: "",
      botResponsePage: false,
      botQuestionPage: true,
      
      //Changes to bots api pagination
      realData: [],
      botsLimit: 5,
      botsOffset: 0,
      currentPageBots: 1,
      

      userResponsesToBot: [],
      fakeQuestionDetails,
      questionDetails: [],
      fakeData: [
        {
          id: 1,
          user_id: 1,
          name: "Lorem ipsum goes preety well with this one",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 2,
          user_id: 1,
          name: "Bot new",
          questions: 128,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 3,
          user_id: 2,
          name: "Bot Cool",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 4,
          user_id: 3,
          name: "Chicken Bot",
          questions: 120,
          response_count: 100,
          project_name: "Chicken",
          actions: "N/A",
        },
        {
          id: 5,
          user_id: 2,
          name: "Bot One",
          questions: 188,
          response_count: 160,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 6,
          user_id: 4,
          name: "Bot cool",
          questions: 10,
          response_count: 130,
          project_name: "Something",
          actions: "N/A",
        },

        {
          id: 7,
          user_id: 1,
          name: "Lorem ipsum goes preety well with this one",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 8,
          user_id: 1,
          name: "Lorem ipsum goes preety well with this one",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 9,
          user_id: 1,
          name: "Lorem ipsum goes preety well with this one",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 10,
          user_id: 1,
          name: "Bot 10",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
        {
          id: 11,
          user_id: 1,
          name: "Bot 11",
          questions: 120,
          response_count: 100,
          project_name: "Something",
          actions: "N/A",
        },
      ],
      fakeQuestions: [],
      searchResults: [],

      fakeOfFakeData: [],
      currentBotName: "",
      currentBotId: 0,
      fakeOfFakeQuestions: [],
      searchText: "",
      downloadImage: img,
      projects: [],
      selectedProject: "",
      currentPageQuestions: 1,
      noResponsesFound: false,
    };
  },
  methods: {
    setNavbarOpen: function() {
      this.navbarOpen = !this.navbarOpen;
    },
    setClickTrue: async function(data) {
      console.log("The data is:", data);
      this.dataClicked = true;
      this.currentBotName = data.title;
      this.currentBotId = data.id;
      const res = await getQuestionByBotId(data.id);
      //console.log(res)
      this.fakeQuestions = res.questions_data;
      console.log(this.fakeQuestions);

      this.setDisplayQuestions();
    },
    setClickFalse: function() {
      this.userResponsesToBot = []

    this.botResponsePage = false;
    this.botQuestionPage = true;

      this.currentBotName = "";
      this.fakeQuestions = [];
      this.fakeOfFakeQuestions = [];
      this.currentBotId = 0;
      this.dataClicked = false;
      this.questionDetails =[];
    },
    openAllResponses: async function(i) {
      //this.fakeQuestionDetails = {}
      this.openResponses = true;
      this.questionDetails = []
      const element = document.querySelector(".custom-responses");
      setTimeout(() => {
        element.classList.add("right-max");
      }, 0);
      //console.log(i.id);
      console.log(
        "Before initialisation with res it is:",
        this.fakeQuestionDetails
      );
      const res = await getQuestionById(i.id);
      //console.log("The Question details of this page are:", res)
      this.questionDetails = res.responses_data;
      this.fakeQuestionDetails = res;

      console.log(
        "After initialisation of res it is:",
        this.fakeQuestionDetails
      );

      console.log(
        "Now printing option:  ",
        this.questionDetails
      );
    },

    openUserResponses(uid) {
      this.currentUid = uid;
      console.log("Open Question Insights",this.currentUid);
      const element = document.querySelector(".custom-responses-user");
      setTimeout(() => {
        element.classList.add("right-max");
      }, 10);
      this.openIndividualUserResponse = true;
    },
    closeUserResponses() {
      const element = document.querySelector(".custom-responses-user");
      setTimeout(() => {
        element.classList.remove("right-max");
      }, 0);
      setTimeout(() => {
        this.openIndividualUserResponse = false;
      }, 300);
    },

    closeAllResponses: function() {
      //console.log(i);
      const element = document.querySelector(".custom-responses");
      setTimeout(() => {
        element.classList.remove("right-max");
      }, 0);
      setTimeout(() => {
        this.openResponses = false;
      }, 300);
    },
    loadAllProjects: async function() {
      const res = await getProjectDetails();
      console.log(res);
      this.projects = [...res.projects];
      this.selectedProject = res.projects[0];
      console.log("Dekh lo bhai aa to raha hai", this.projects);
    },


    setDisplayQuestions() {
      if (this.fakeQuestions.length % this.numberOfQuestionsPerPage == 0) {
        let startingPageIndex = parseInt(
          (this.currentPageQuestions - 1) * this.numberOfQuestionsPerPage
        );
        let endingPageIndex =
          parseInt(startingPageIndex) + parseInt(this.numberOfQuestionsPerPage);
        let newPageData = [];
        for (let i = startingPageIndex; i < endingPageIndex; i++) {
          newPageData.push(this.fakeQuestions[i]);
        }

        this.fakeOfFakeQuestions = newPageData;
        console.log(
          "This is the value of Fake Of Fake Data",
          this.fakeOfFakeQuestions
        );
      } else {
        //It is in the last page
        if (
          Math.ceil(
            this.fakeQuestions.length / this.numberOfQuestionsPerPage
          ) === this.currentPageQuestions
        ) {
          let startingPageIndex = parseInt(
            (this.currentPageQuestions - 1) * this.numberOfQuestionsPerPage
          );
          let endingPageIndex =
            parseInt(startingPageIndex) +
            parseInt(this.fakeQuestions.length % this.numberOfQuestionsPerPage);
          let newPageData = [];
          for (let i = startingPageIndex; i < endingPageIndex; i++) {
            newPageData.push(this.fakeQuestions[i]);
          }
          console.log(
            "Starting data:",
            startingPageIndex,
            " Ending Data : ",
            endingPageIndex
          );
          this.fakeOfFakeQuestions = newPageData;
        }

        //It is not in the second last page
        else {
          let startingPageIndex = parseInt(
            (this.currentPageQuestions - 1) * this.numberOfQuestionsPerPage
          );
          let endingPageIndex =
            parseInt(startingPageIndex) +
            parseInt(this.numberOfQuestionsPerPage);
          let newPageData = [];
          for (let i = startingPageIndex; i < endingPageIndex; i++) {
            newPageData.push(this.fakeQuestions[i]);
          }
          console.log(newPageData);
          console.log(
            "Starting data:",
            startingPageIndex,
            " Ending Data : ",
            endingPageIndex
          );

          this.fakeOfFakeQuestions = newPageData;
        }
      }
    },
    nextPageDisplayQuestions() {
      // IF the number of bots is less the number of bots per page we dont increment
      if (
        this.fakeQuestions.length < this.numberOfQuestionsPerPage ||
        this.fakeQuestions.length <=
          this.numberOfQuestionsPerPage * this.currentPageQuestions
      ) {
        console.log(this.currentPageQuestions);
        console.log("Not incrementing anything");
      } else {
        // If the number of bots is greater than the number of bots per page but is not a multiple of bots per page
        this.currentPageQuestions += 1;
        this.setDisplayQuestions();
      }
    },
    previousPageDisplayQuestions() {
      if (this.currentPageQuestions > 1) {
        this.currentPageQuestions -= 1;
        this.setDisplayQuestions();
      }
    },

    async getBotInsights() {
      const data = await botInsights(this.botsOffset, this.botsLimit);
      //console.log("This is the data from the api",data)
      if(data.bots.length > 0){
        this.realData = data.bots;
        console.log("This is the real data assignment", this.realData);
      }
      else{
        this.realData = data.bots;
        console.log('No more data left for bots ');
      }
    },

    async previousPageDisplayBots(){
      
      this.botsOffset -= this.botsLimit;
      console.log('Value of offset:', this.botsOffset)
      if(this.botsOffset  >= 0  ){
        console.log('It is possible');
        this.realData= []
        await this.getBotInsights(this.botsOffset, this.botsLimit);
        this.currentPageBots -=1;
      }
      else{
      this.botsOffset += this.botsLimit;


        alert('Cannot go back!');
      }
    },

    async nextPageDisplayBots(){
        

        this.botsOffset = this.botsOffset + this.botsLimit;
        console.log('Value of offset:', this.botsOffset)
        let realDataStore = this.realData;
        this.realData= []
        await this.getBotInsights(this.botsOffset, this.botsLimit);
        if(this.realData.length == 0){
          this.botsOffset -= this.botsLimit;
          this.realData = realDataStore;
        }
        else{
          this.currentPageBots +=1;

        }
        
      },
     


    


    searchQuestions() {
      //console.log(this.fakeQuestions)
      if (this.searchText) {
        let searchedQuestions = this.fakeQuestions.filter((q) =>
          q.question.toLowerCase().includes(this.searchText.toLowerCase())
        );

        this.searchResults = [...searchedQuestions];
        console.log(this.searchResults);
      }
    },

    downloadCsv() {
      console.log("Data to be downloaded now!!!");
      const data = this.fakeQuestions;
      console.log(data);

      const csvString = [
        ["ID", "Questions", "Responses"],
        ...data.map((item) => [
          item.id,
          item.question.replace(",", ""),
          item.response_count,
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");

      console.log(csvString);

      var download = function(content, fileName, mimeType) {
        var a = document.createElement("a");
        mimeType = mimeType || "application/octet-stream";

        if (navigator.msSaveBlob) {
          // IE10
          navigator.msSaveBlob(
            new Blob([content], {
              type: mimeType,
            }),
            fileName
          );
        } else if (URL && "download" in a) {
          //html5 A[download]
          a.href = URL.createObjectURL(
            new Blob([content], {
              type: mimeType,
            })
          );
          a.setAttribute("download", fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          location.href =
            "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
        }
      };

      download(csvString, "download.csv", "text/csv;encoding:utf-8");
    },

    downloadCsvForInsights() {
      console.log("Data to be downloaded now!!!");
      const data = this.userResponsesToBot;
      console.log(data);

      const csvString = [
        ["ID", "Response Info", "Status", "Date"],
        ...data.map((item, index) => [
          index + 1,
          item.uid,
          item.count,
          "12/12/2021",
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");

      console.log(csvString);

      var download = function(content, fileName, mimeType) {
        var a = document.createElement("a");
        mimeType = mimeType || "application/octet-stream";

        if (navigator.msSaveBlob) {
          // IE10
          navigator.msSaveBlob(
            new Blob([content], {
              type: mimeType,
            }),
            fileName
          );
        } else if (URL && "download" in a) {
          //html5 A[download]
          a.href = URL.createObjectURL(
            new Blob([content], {
              type: mimeType,
            })
          );
          a.setAttribute("download", fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          location.href =
            "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
        }
      };

      download(csvString, "download.csv", "text/csv;encoding:utf-8");
    },

    async getQuestionsPage() {
      this.botResponsePage = true;
      this.botQuestionPage = false;
      const data = await getQuestionInsightsByBotId(this.currentBotId);
      console.log("Enterting this !");
      console.log("The bot id insights data is", data);
      if (data.responses.length === 0) {
        this.noResponsesFound = true;
      } else {
        this.userResponsesToBot = []
        this.userResponsesToBot.push(...data.responses);
        console.log("I am changing this", this.userResponsesToBot);
        // this.openUserResponses(this.userResponsesToBot[0].uid);
      }
    },

    async navigateToIndividualResponsePage(uuid) {
      this.closeAllResponses();
      this.openUserResponses(uuid);
    },

    async deleteUserBot(id){
      console.log("this bot will be deleted",id)
      const res = await deleteBots(id)
      console.log(res)
      if(res.success){
      this.realData = this.realData.filter((bot) =>{
        if(bot.id !== id){
          return bot
        } 
      })
      alert('The deleted bot is:', id )
      console.log("the modified fake data is:", this.realData)
      }
      else{
        alert('Unsuccessful deletion')
      }

    },



    checkUserPresence() {
      const user = localStorage.getItem("access_token");
      if (!user) {
        this.$router.push("/login");
      }
    },

  },
  mounted() {
    this.getBotInsights();
  },
  created() {
    this.checkUserPresence();
    this.loadAllProjects();
    this.setDisplayQuestions();
  },
  watch: {
    fixedNumberOfData: function() {
      this.numberOfBotsPerPage = this.fixedNumberOfData;
      this.currentPageBots = 1;
      this.setDisplayBots();
    },
    numberOfQuestionsPerPage: function() {
      this.currentPageQuestions = 1;
      this.setDisplayQuestions();
    },
    searchText: function() {
      this.searchQuestions();
    },
    currentUid: function() {
      console.log(this.currentUid, "is being changed");
    },
    fakeQuestionDetails: function() {
      console.log(this.fakeQuestionDetails, "in watch method");
    },
    botsLimit : async function() {
      this.botsLimit = parseInt(this.botsLimit)
      if(parseInt(this.botsLimit) > 0 && parseInt(this.botsLimit)){
      this.realData = []
      this.currentPageBots = 1;
      this.botsOffset = 0;
      await this.getBotInsights();
      }
      
    }
    
  },

  components: {
    IndexNavbar,
    Loadingdata,
    ResponsesByUser,
  },
};
</script>

<style scoped>
.project {
  background-color: #f6fbff;
  border: none;
}
.search_item {
  border-radius: 3rem;
  width: 25rem;
  background-color: #f4f2ff;
  box-shadow: 0 4px 4px rgba(77, 162, 255, 0.12);
  border: 1px solid rgba(1, 1, 1, 0.2);
}

.filterStyle {
  width: 4.5rem;
  height: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #444;
  border-right: 1px solid #444;
  border-top: 1px solid #444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
}
.exportStyle {
  width: 4.5rem;
  height: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  margin-right: 2rem;
  cursor: pointer;
}

.exportStyle img {
  width: 18px;
  height: auto;
  padding-left: 5px;
}

.btn-create {
  display: flex;
  width: 15rem;
  height: 4rem;
}
.manageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.manageContainer .manageBotLink {
  border: 1px solid #73bcfb;
}
.blue {
  background-color: #367bf5;
}
.plusSign {
  width: 20px;
  height: 20px;
  font-weight: 500;
}

.cardBackground {
  background-color: #fff;
  box-shadow: -1px 1px 55px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 1px 55px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 1px 55px 0px rgba(0, 0, 0, 0.1);
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.17);
}
.tableData:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.tableData td:first-child {
  width: 50%;
}
.tableData td:not(:first-child) {
  width: calc(50% / 4);
}

.tableData:hover td {
  font-weight: 600;
}

/* Bot container stuffs */

.bot_container {
  width: 90%;
  margin: 0 auto;
  background-color: #f6fbff;
}
.border-three {
  border: 1px solid black;
  border-bottom: none;
  border-top-right-radius: 0.3em;
  border-top-left-radius: 0.3em;
}

.back_button {
  position: absolute;
  top: 6rem;
  left: 2rem;
  color: #367bf5;
  cursor: pointer;
}

.bot_container .question_table th {
  border-bottom-color: rgba(1, 1, 1, 0.7);
  background-color: rgba(243, 244, 246, 1);
  width: calc(100% / 6);
}
.bot_container .question_table td {
  width: calc(100% / 6);
}
.tablerow {
  background-color: #fff;
  border-radius: 5px;
}
.tablerow:hover {
  background-color: #eff7ff;
}
.btn-view {
  border-radius: 10px;
}
.question_table {
  border-collapse: separate;
  border-spacing: 0 2px;
}
.custom-status-block {
  height: 2em;
  width: 6em;
}

/* Bot container ends here */

.custom-responses {
  z-index: 9999999;
  height: 100vh;
  width: 40%;
  top: 0;
  right: -40%;
  transition: 0.3s ease;
  overflow: auto;
}
.custom-responses-user {
  z-index: 9999999;
  height: 100vh;
  overflow: auto;
  width: 40%;
  top: 0;
  right: -40%;
  transition: 0.3s ease;
}
.right-max {
  right: 0;
  transition: 0.3s ease;
}
.custom-pagination-page {
  margin-right: 2em;
}
.custom-pagination-page input {
  width: 3em;
  text-align: center;
  border: 2px solid var(--blue);
  border-radius: 10px;
  margin: 0 0.3em;
}
.custom-pagination-page button {
  background-color: #444;
  color: var(--white);
  height: 1.8em;
  width: 1.8em;
  border-radius: 3px;
}
.custom-pagination-data input {
  width: 3em;
  text-align: center;
  border: 2px solid var(--blue);
  border-radius: 10px;
  margin: 0 0.3em;
}
.custom-pagination-data label {
  font-size: 0.8rem;
}

.custom-search-results {
  min-height: 10rem;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: #fff;
  color: #000;
  position: absolute;
}

.custom-question-response-table {
  width: 100%;
}
.custom-question-response-heading {
  padding: 1rem 1rem;
  height: 3rem;
  background-color: #e5e5e5;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
}
.custom-question-response-data {
  padding: 1rem 1rem;
  height: 3rem;
  background-color: #fff;
  text-align: left;
  border-bottom: 1px solid rgb(219, 216, 216);
}
.custom-width-33 {
  width: 33%;
}
.custom-expand-icon {
  font-size: 1.3rem;
  height: 3rem;
  width: 10rem;
  margin: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

 /* Chrome, Safari, Edge, Opera */
.pagination::-webkit-outer-spin-button,.pagination::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pagination[type=number] {
  -moz-appearance: textfield;
}
</style>

<style>
.blueShadow {
  box-shadow: 0px 5px 20px rgba(115, 188, 251, 0.5);
}
</style>
