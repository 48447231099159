<template>
  <div class="custom-background w-100 h-screen">
    <div class="custom-container">
      <div class="custom-col">
        <div class="custom-card">
          <div class="custom-card-header">
            <h2 class="custom-title">Starter</h2>
            <p class="custom-price">
              0
              <span>$/m</span>
            </p>
            <span class="custom-description">Free Plan forever</span>
            <div class="custom-line"></div>
          </div>

          <ul class="custom-features">
            <li>- Item One</li>
            <li>- Item Two</li>
            <li>- Item Three</li>
            <li>- Item Four</li>
          </ul>
          <a href="" class="custom-btn">Free plan</a>
        </div>
      </div>
      <div class="custom-col special">
        <div class="custom-card ">
          <div class="custom-card-header">
            <h2 class="custom-title special">Go Pro</h2>
            <p class="custom-price special">
              10
              <span>$/m</span>
            </p>
            <span class="custom-description special">Most popular plan</span>
            <div class="custom-line special"></div>
          </div>
          <div class="custom-ball"></div>

          <ul class="custom-features">
            <li>- Item One</li>
            <li>- Item Two</li>
            <li>- Item Three</li>
            <li>- Item Four</li>
            <li>- Item Five</li>
          </ul>
          <a href="" class="custom-btn">Professional Plan</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Sniglet");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: #252435;
}

.custom-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.custom-col {
  width: 22rem;
  height: 35rem;
  text-align: center;
  margin: 0 1rem;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.custom-ball {
  width: 50rem;
  height: 50rem;
  position: absolute;
  top: -105%;
  left: -40%;
  background-color: rgb(68, 105, 253);
  border-radius: 50%;
  z-index: -1;
}

.custom-card {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden !important;
  z-index: 0;
  position: relative;
}
.custom-col.special {
  box-shadow: 1px 10px 30px -3px rgba(0, 0, 0, 0.13);
}

.custom-card-header {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.custom-card-header .custom-title {
  font-size: 1.5em;
  font-weight: 600;
  color: rgba(37, 36, 53, 0.9);
}
.custom-card-header .custom-title span {
  color: #395ffe;
}
.custom-card-header .custom-title.special {
  color: #fff !important;
}

.custom-card-header .custom-price {
  font-family: "Sniglet", cursive;
  font-size: 2.5em;
  color: #395ffe;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}
.custom-card-header .custom-price.special {
  color: #fff !important;
}
.custom-card-header .custom-price span {
  font-size: 0.5em;
  color: inherit;
}
.custom-card-header .custom-description {
  color: rgba(37, 36, 53, 0.7);
}
.custom-card-header .custom-description.special {
  color: #fff;
}
.custom-features {
  height: 70%;
  text-align: left;
  list-style: none;
  padding: 1rem;
}
.custom-features li {
  color: rgba(37, 36, 53, 0.7);
  margin-bottom: 1rem;
}

.custom-line {
  width: 25%;
  margin: 15px auto 0;
  height: 3px;
  background-color: rgba(40, 90, 255, 0.9);
}
.custom-line.special {
  background-color: rgba(255, 255, 255, 0.6);
}

.custom-btn {
  width: 90%;
  display: inline-block;
  margin: 0 auto;
  padding: 1rem;
  background-color: rgba(40, 90, 255, 0.9);
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
}
.custom-btn:hover {
  background-color: #395ffe;
}
</style>
