<template>
  <div>
    <div
      class="my-2 mb-8"
      v-for="(question, index) in questionList"
      :key="index"
    >
      <!-- Small Answer ====================================          -->
      <div
        class="custom-question-card rounded bg-col-blue relative flex flex-col justify-start p-4 my-2"
        v-if="question.type == 4"
      >
        <div class="flex w-full justify-between">
          <div class="icons flex">
            <div class="relative">
              <img
                :src="eyes"
                title="Preview"
                @click="setPreviewQuestion(question)"
                alt="eyes"
                class="mr-1 custom-icon"
                :class="previewQuestion.question_text==question.question_text?'preview-border':''"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Preview
              </div>
            </div>
            <div class="relative">
              <img
                :src="info"
                title="Info"
                alt="info"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Information
              </div>
            </div>
            <div class="relative">
              <img
                :src="files"
                title="Copy"
                alt="files"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Copy
              </div>
            </div>
            <div class="relative">
              <img
                :src="del"
                title="Delete"
                alt="delete"
                @click="useRemoveQuestion(index)"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Delete
              </div>
            </div>
          </div>
          <select
            name="questions"
            id="questions"
            class="custom-select"
            v-model="question.type"
            @change="useEmptyQuestionDetails(index)"
          >
            <option :value="3">Long Answer</option>
            <option :value="4">Small Answer</option>
            >
            <option :value="1">Single Choice</option>
            <option :value="2">Multiple Choice</option>
          </select>
        </div>

        <input
          type="text"
          class="custom-question my-2 rounded "
          placeholder="Question"
          v-model="question.question_text"
        />
        <input
          type="text"
          class="custom-answer rounded"
          placeholder="Short Answer"
          disabled
          v-model="question.answer"
        />
      </div>

      <!-- Long Answer =================================================== -->
      <div
        class="custom-question-card rounded bg-col-blue relative flex flex-col justify-start p-4"
        v-if="question.type == 3"
      >
        <div class="flex justify-between">
          <div class="icons flex">
            <div class="relative">
              <img
                :src="eyes"
                title="Preview"
                @click="setPreviewQuestion(question)"
                alt="eyes"
                class="mr-1 custom-icon"
                :class="previewQuestion===question?'preview-border':''"

              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Preview
              </div>
            </div>
            <div class="relative">
              <img
                :src="info"
                title="Info"
                alt="info"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Information
              </div>
            </div>
            <div class="relative">
              <img
                :src="files"
                title="Copy"
                alt="files"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Copy
              </div>
            </div>
            <div class="relative">
              <img
                :src="del"
                title="Delete"
                alt="delete"
                @click="useRemoveQuestion(index)"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Delete
              </div>
            </div>
          </div>
          <select
            name="questions"
            id="questions"
            class="custom-select"
            v-model="question.type"
            @change="useEmptyQuestionDetails(index)"
          >
            <option :value="3">Long Answer</option>
            <option :value="4">Small Answer</option>
            >
            <option :value="1">Single Choice</option>
            <option :value="2">Multiple Choice</option>
          </select>
        </div>

        <textarea
          type="text"
          class="custom-question my-2 rounded"
          placeholder="Question"
          v-model="question.question_text"
          rows="2"
        ></textarea>
        <textarea
          type="text"
          class="custom-answer rounded"
          placeholder="Long Answer"
          rows="5"
          disabled
          v-model="question.answer"
        ></textarea>
      </div>

      <!-- ====================   MCQ Answer    ======================== -->
      <div
        class="custom-question-card rounded bg-col-blue relative flex flex-col justify-start p-4"
        v-if="question.type == 1"
      >
        <div class="flex justify-between">
          <div class="icons flex">
            <div class="relative">
              <img
                :src="eyes"
                title="Preview"
                @click="setPreviewQuestion(question)"
                alt="eyes"
                class="mr-1 custom-icon"
                :class="previewQuestion===question?'preview-border':''"

              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Preview
              </div>
            </div>
            <div class="relative">
              <img
                :src="info"
                title="Info"
                alt="info"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Information
              </div>
            </div>
            <div class="relative">
              <img
                :src="files"
                title="Copy"
                alt="files"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Copy
              </div>
            </div>
            <div class="relative">
              <img
                :src="del"
                title="Delete"
                alt="delete"
                @click="useRemoveQuestion(index)"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Delete
              </div>
            </div>
          </div>
          <select
            name="questions"
            id="questions"
            class="custom-select"
            v-model="question.type"
            @change="useEmptyQuestionDetails(index)"
          >
            <option :value="3">Long Answer</option>
            <option :value="4">Small Answer</option>
            >
            <option :value="1">Single Choice</option>
            <option :value="2">Multiple Choice</option>
          </select>
        </div>
        <input
          type="text"
          class="custom-question my-2 rounded"
          placeholder="Question"
          v-model="question.question_text"
        />
        <div
          v-for="(option, id) in question.options"
          :key="id"
          class="flex items-center pb-2"
        >
          <input
            type="radio"
            @click="setAnswerSingleRadio(index, option)"
            v-model="question.answer"
            :value="option"
            class="mr-2"
            disabled
          />
          <label for="question.question_text" class="custom-radio-label">{{
            option.option_text
          }}</label>
          <div
            class="custom-delete rounded-full bg-col-red text-2xl flex justify-center items-center cursor-pointer color-white ml-2"
            @click="removeSingleAnswerRadio(index, option)"
          >
            <span>-</span>
          </div>
        </div>
        <div class="flex items-center">
          <input
            type="text"
            v-model="newOption[index]"
            placeholder="Add new Option"
            class="custom-option-add"
          />

          <div
            v-if="newOption"
            class="custom-add-option rounded-full bg-col-green text-2xl flex justify-center items-center cursor-pointer color-white ml-2"
            @click="addRadioOptions(index, newOption)"
          >
            <span>+</span>
          </div>
          <div
            v-if="!newOption"
            class="custom-add-option custom-add-option-disabled rounded-full bg-col-green text-2xl flex justify-center items-center cursor-none color-white ml-2 opacity-30"
          >
            <span>+</span>
          </div>
        </div>
      </div>
      <!-- ====================   MCQ Multiple Answer    ======================== -->
      <div
        class="custom-question-card rounded bg-col-blue relative flex flex-col justify-start p-4"
        v-if="question.type == 2"
      >
        <div class="flex justify-between">
          <div class="icons flex">
            <div class="relative">
              <img
                :src="eyes"
                title="Preview"
                @click="setPreviewQuestion(question)"
                alt="eyes"
                class="mr-1 custom-icon"
                :class="previewQuestion===question?'preview-border':''"

              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Preview
              </div>
            </div>
            <div class="relative">
              <img
                :src="info"
                title="Info"
                alt="info"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Information
              </div>
            </div>
            <div class="relative">
              <img
                :src="files"
                title="Copy"
                alt="files"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Copy
              </div>
            </div>
            <div class="relative">
              <img
                :src="del"
                title="Delete"
                alt="delete"
                @click="useRemoveQuestion(index)"
                class="mr-1 custom-icon"
              />
              <div class="custom-tooltip absolute bg-col-dark color-white">
                Delete
              </div>
            </div>
          </div>
          <select
            name="questions"
            id="questions"
            class="custom-select"
            v-model="question.type"
            @change="useEmptyQuestionDetails(index)"
          >
            <option :value="3">Long Answer</option>
            <option :value="4">Small Answer</option>
            >
            <option :value="1">Single Choice</option>
            <option :value="2">Multiple Choice</option>
          </select>
        </div>
        <input
          type="text"
          class="custom-question my-2 rounded"
          placeholder="Question"
          v-model="question.question_text"
        />
        <div
          v-for="(option, id) in question.options"
          :key="id"
          class="flex items-center pb-2"
        >
          <input
            v-if="useIsOptionInMultipleOptions(index, option)"
            type="checkbox"
            disabled
            :value="option"
            class="mr-2 rounded"
            @click="useSetAnswerMultiple(index, option)"
          />
          <input
            v-if="!useIsOptionInMultipleOptions(index, option)"
            type="checkbox"
            :value="option"
            disabled
            class="mr-2 rounded"
            @click="useSetAnswerMultiple(index, option)"
          />
          <label for="question.question_text" class="custom-radio-label">{{
            option.option_text
          }}</label>
          <div
            class="custom-delete rounded-full bg-col-red text-2xl flex justify-center items-center cursor-pointer color-white ml-2"
            @click="useRemoveMultipleAnswers(index, option)"
          >
            <span>-</span>
          </div>
        </div>
        <div class="flex items-center">
          <input
            type="text"
            v-model="newOption[index]"
            placeholder="Add new Option"
            class="custom-option-add"
          />

          <div
            v-if="newOption"
            class="custom-add-option rounded-full bg-col-green text-2xl flex justify-center items-center cursor-pointer color-white ml-2"
            @click="addRadioOptions(index, newOption)"
          >
            <span>+</span>
          </div>
          <div
            v-if="!newOption"
            class="custom-add-option custom-add-option-disabled rounded-full bg-col-green text-2xl flex justify-center items-center cursor-none color-white ml-2 opacity-30"
          >
            <span>+</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Small from "../Questions/Small.vue";
import eyes from "../../assets/img/eye_icon.svg";
import info from "../../assets/img/info_icon.svg";
import files from "../../assets/img/files_icon.svg";
import del from "../../assets/img/delete_icon.svg";
export default {
  data() {
    return {
      questionType: "Small Answer",
      eyes: eyes,
      info: info,
      files: files,
      del: del,
      newOption: [],
      jhamela: "",
    };
  },
  methods: {
    addRadioOptions(index, newOption) {
      this.addOptionInList(index, newOption[index]);
      this.newOption[index] = "";
    },
    setAnswerSingleRadio(index, option) {
      this.setAnswerSingle(index, option);
    },
    removeSingleAnswerRadio(index, option) {
      this.removeAnswerSingle(option, index);
    },
    useEmptyQuestionDetails(index) {
      this.emptyQuestionDetails(index);
    },
    useSetAnswerMultiple(index, option) {
      this.setAnswerMultiple(index, option);
    },
    useRemoveMultipleAnswers(index, option) {
      this.removeMultipleAnswers(index, option);
    },
    useIsOptionInMultipleOptions(index, option) {
      this.isOptionInMultipleOptions(index, option);
    },
    useRemoveQuestion(index) {
      this.removeQuestion(index);
    },
  },
  components: {
    // Small,
  },
  props: [
    "questionList",
    "addOptionInList",
    "setAnswerSingle",
    "removeAnswerSingle",
    "emptyQuestionDetails",
    "setAnswerMultiple",
    "removeMultipleAnswers",
    "isOptionInMultipleOptions",
    "removeQuestion",
    "setPreviewQuestion",
    "previewQuestion"
  ],
};
</script>
<style scoped>
.custom-question-card {
  min-height: 15em;
  background-color: #fff;
  width: 90%;
  box-shadow: 0px 5px 20px -4px rgba(1, 1, 1, 0.2);
  margin: 0 auto;
}
.custom-select {
  width: 20em;
  z-index: 0;
}
.custom-select::after {
  content: "";
  position: absolute;
  background-color: var(--blue);
  z-index: 1;
  top: 0;
}
.custom-select option {
  width: 20em;
  padding: 2em 0;
}
.custom-question {
  border: none;
  border: 2px solid var(--blue);
  font-size: 1.4em;
  margin: 1rem 0;
  box-shadow: 0px 10px 30px -5px rgba(54, 123, 245, 0.2);
}
.custom-answer {
  border: 1px solid #333;
}
.custom-icon {
  width: 20px;
  height: auto;
  margin: 0 1rem;
  transition: 0.2s ease;
}
.custom-option-add {
  min-width: 8em;
  max-width: 10em;
  padding: 0;
  border-radius: 10rem;
  padding: 0.2rem 0.5rem;
  border: 2px solid var(--blue);
}
input[type="radio"],
input[type="checkbox"] {
  box-shadow: 0px 5px 10px -2px rgba(133, 158, 211, 0.7);
  border: 3px solid var(--blue);
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: var(--blue);
  transition: 0.1s ease;
}
input[type="radio"]:checked ~ label,
input[type="checkbox"]:checked ~ label {
  color: var(--blue);
}
input[type="radio"]:checked:hover,
input[type="checkbox"]:checked:hover {
  opacity: 0.7;
}
input[type="radio"],
input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.custom-delete {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  line-height: 0;
  border: 2px solid var(--red);
  box-shadow: 0px 5px 10px -2px rgba(133, 158, 211, 0.7);
  transition: 0.1s ease;
}

.custom-delete span {
  margin-top: -5px;
  transition: 0.1s ease;
}

.custom-radio-label {
  font-size: 1.2rem;
}
.custom-add-option {
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: 0px 5px 10px -2px rgba(133, 158, 211, 0.7);
  transition: 0.3s ease;
}

.custom-add-option span {
  margin-top: -5px;
}
.custom-add-option-disabled {
  pointer-events: none;
  background-color: #bcc1be;
}
.custom-icon:hover ~ .custom-tooltip {
  opacity: 1;
}
.custom-tooltip {
  padding: 0.3rem 1rem;
  opacity: 0;
  pointer-events: none;
  top: -100%;
  border-radius: 0.5rem;
  transition: 0.2s ease;
}
.preview-border{
  border: 0.1rem solid var(--blue);
  border-radius: 100%;
}
</style>
