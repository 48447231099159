<template>
  <div class="h-screen custom-background">
    <IndexNavbar />
    <div class="custom-container flex justify-center items-center">
      <div class="custom-card bg-col-white p-4">
        <div class="text-4xl font-bold">Sign Up Now</div>
        <div class="flex h-full w-full">
          <div class="custom-column ">
            <div class="custom-input-field">
              <input
                type="text"
                placeholder="First name *"
                v-model="first_name"
              />
            </div>
            <div class="custom-input-field">
              <input
                type="text"
                placeholder="Last name *"
                v-model="last_name"
              />
            </div>
            <div class="custom-input-field">
              <input type="email" placeholder="Email *" v-model="email" />
            </div>
          </div>
          <div class="custom-column ">
            <div class="custom-input-field">
              <input
                type="text"
                placeholder="Organization"
                v-model="organization"
              />
            </div>
            <div class="custom-input-field relative">
              <input
                :type="passwordType"
                placeholder="Password *"
                v-model="password"
              />
              <div
                class="custom-eye absolute cursor-pointer"
                @click="togglePasswordType"
              >
                <FontAwesomeIcon icon="eye" v-if="passwordType == `password`" />
                <FontAwesomeIcon
                  icon="eye-slash"
                  v-if="passwordType != `password`"
                />
              </div>
            </div>
            <div class="custom-input-field">
              <vue-tel-input
                v-model="phoneNumber"
                mode="international"
              ></vue-tel-input>
            </div>
          </div>
        </div>
        <div class="custom-checkbox flex items-center">
          <input
            type="checkbox"
            name="agreeToTermsAndCondition"
            id="agreeToTermsAndCondition"
            v-model="agreeToTermsAndCondition"
            @click="clickToToggleAgreeToTermsAndCondition"
          /><label for="agreeToTermsAndCondition" class="  ml-2"
            >I here by agree to the terms and condition set by the
            company</label
          >
        </div>
        <div class="w-full flex justify-between items-center">
          <div
            class="custom-button cursor-pointer color-white flex justify-center items-center rounded font-semibold"
            @click="clickToRegister"
            v-if="!isSubmitting && first_name && last_name && email && password"
          >
            Sign Up
          </div>

          <div
            class="custom-button-gray cursor-pointer bg-col-gray color-white flex justify-center items-center rounded font-semibold is-disabled"
            @click="clickToRegister"
            v-else
          >
            Sign Up
          </div>

          <div
            class="custom-button bg-col-green rounded flex justify-center items-center"
            v-if="isSubmitting"
          >
            <Buttonloading class="custom-button-loading" />
          </div>
          <div>
            Already Have an account?
            <router-link to="/login" class="color-blue underline "
              >Log in</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import { VueTelInput } from "vue3-tel-input";
import { signUp } from "@/utils/api.js";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Buttonloading from "../../components/Buttonloading/Buttonloading.vue";

library.add(faEye, faEyeSlash);

export default {
  data() {
    return {
      phoneNumber: "",
      agreeToTermsAndCondition: false,
      first_name: "",
      last_name: "",
      email: "",
      organization: "",
      password: "",
      phone_number: "",
      passwordType: "password",
      isSubmitting: false,
    };
  },

  methods: {
    clickToToggleAgreeToTermsAndCondition() {
      this.agreeToTermsAndCondition = !this.agreeToTermsAndCondition;
    },
    async clickToRegister() {
      this.isSubmitting = true;
      const userRegestrationData = {
        phone_number: this.phoneNumber,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        organization: this.organization,
        default_theme: "dark",
        password: this.password,
      };
      console.log(userRegestrationData);

      if (this.agreeToTermsAndCondition) {
        const data = await signUp(userRegestrationData);
        console.log(data);
        this.$router.push("/login");
      } else {
        this.isSubmitting = false;
      }
      console.log();
    },
    togglePasswordType() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
  components: {
    IndexNavbar,
    Buttonloading,

    VueTelInput,
    FontAwesomeIcon,
  },
  watch: {
    phoneNumber: function() {
      console.log(this.phoneNumber);
    },
  },
};
</script>
<style scoped>
.custom-button-loading {
  height: 4rem;
  width: 4rem;
  position: relative;
  margin-top: 2rem;
}
.custom-background {
  background: url(../../assets/img/dark_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.custom-container {
  height: calc(100% - 4rem);
}
.custom-card {
  min-height: 30em;
  min-width: 50em;
  border-radius: 1rem;
  position: relative;
  box-shadow: 0 10px 20px -5px rgba(1, 1, 1, 0.16);
}
.custom-column {
  width: 50%;
  padding: 1rem 2rem 1rem 0;
}
.custom-input-field {
  margin: 2rem 0;
}
.custom-input-field input {
  width: 100%;
  border: none;
  border-bottom: 3px solid var(--dark2);
  outline: none;
  transition: 0.4s ease;
  padding: 0.5em 0 0.5em 0;
  background-color: var(--white);
}
.custom-checkbox {
  margin-bottom: 2rem;
}
.custom-checkbox input {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.2rem;
  outline: none;
}
.custom-checkbox label {
  font-size: 1.1rem;
}
.custom-button {
  width: 10em;
  height: 3rem;
  padding: 0.5em 0;
  font-size: 1.125rem;
  transition: 0.3s ease;
  border: 3px solid var(--green);
}
.custom-button-gray {
  width: 10em;
  height: 3rem;
  padding: 0.5em 0;
  font-size: 1.125rem;
  transition: 0.3s ease;
  border: 3px solid var(--gray);
}
.custom-button:hover {
  background-color: var(--white);
  color: var(--dark2);
}
.custom-eye {
  top: 20%;
  right: 5%;
}
.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
