<template>
  <div class="flex justify-center">
    <img
      src="../../assets/animations/404Animation.gif"
      class="custom-loading"
      alt=""
    />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.custom-loading {
  height: auto;
  width: 50%;
}
</style>
