<template>
  <div class="custom-preview flex flex-col relative">
    <div class="custom-top p-4 font-bold flex items-center">
      <div class="custom-circle bg-col-dark rounded-full"></div>
      {{ bot_name }}
    </div>
    <div class="custom-bottom p-4 flex justify-between items-start flex-col">
      <div class="custom-question text-sm bg-col-blue color-white">
          {{ question.question_text }}
        </div>
      <div class="flex flex-col">
        
        
        <div
          class="custom-option-list flex"
          v-if="question.type == 1 || question.type == 2"
        >
          <div
            class="custom-option"
            v-for="(item, index) in question.options"
            :key="index"
          >
            {{ item.option_text }}
          </div>
        </div>
      </div>

      <div class="custom-input flex items-center color-white text-sm">
        Write A message
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question", "bot_name"],
};
</script>

<style scoped>
.custom-preview {
  height: 25rem;
  width: 17rem;
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--dark2);
  overflow: hidden;
}
.custom-top {
  height: 20%;
  border-bottom: 1px solid rgba(0, 100, 217, 0.2);
  box-shadow: 0px 10px 30px -5px rgba(54, 123, 245, 0.2);
}
.custom-bottom {
  height: 80%;
  overflow: auto;
}
.custom-question {
 
  max-width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  border-radius: 1rem 1rem 1rem 5px;
  box-shadow: 0px 10px 10px 0px rgba(54, 123, 245, 0.2);
  padding: 0.5em 1em;
}
.custom-input {
  width: 100%;
  height: 3rem;
  border-radius: 100rem;
  background-color: #b0befd;
  padding: 0.5rem 1rem;
}
.custom-circle {
  height: 2.75rem;
  width: 2.75rem;
  margin-right: 1rem;
}
.custom-option-list {
  flex-wrap: wrap;
}
.custom-option {
  padding: 0.25rem 1rem;
  border-radius: 10rem;
  border: 2px solid var(--blue);
  margin: 0.5rem;
  flex-wrap: wrap;
  margin-left: 0;
}
</style>
