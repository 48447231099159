// mouting point for the whole app
import App from "@/App.vue";
import "@/assets/styles/tailwind.css";
import Bot from "@/views/Bot/Bot.vue";
import CreateBot from "@/views/CreateBot/CreateBot.vue";
import CreateProject from "@/views/CreateProject/CreateProject.vue";
import GeneratedLink from "@/views/GeneratedLink/GeneratedLink.vue";
import HomePage from "@/views/HomePage/HomePage.vue";
// views without layouts
import Landing from "@/views/Landing.vue";
import Manage from "@/views/Manage/Manage.vue";
import newAuth from "@/views/new-auth/Login.vue";
import newRegistration from "@/views/new-auth/Registration.vue";
import Pricing from "@/views/Pricing/Pricing.vue";
import Profile from "@/views/Profile/Profile.vue";
// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { checkPrivateRouting } from "./utils/PathCheck";
import firebase from 'firebase/compat/app';


//import { checkPrivateRouting } from "./utils/PathCheck";
// routes

const routes = [
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/manage",
    component: Manage,
  },
  {
    path: "/createbot/:project?",
    component: CreateBot,
  },
  {
    path: "/createproject",
    component: CreateProject,
  },
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/login",
    component: newAuth,
  },
  {
    path: "/pricing",
    component: Pricing,
  },
  {
    path: "/registration",
    component: newRegistration,
  },
  {
    path: "/link/:bot_id",
    name: "link",
    component: GeneratedLink,
  },
  {
    path: "/bot/:bot_id",
    name: "bot",
    component: Bot,
  },

  { path: "/:pathMatch(.*)*", redirect: "/home" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


var firebaseConfig = {
  apiKey: "AIzaSyDt4uXH0ZMX8_FbSrECgIwXrpN3AbD4H-Y",
  authDomain: "formsbot-e470c.firebaseapp.com",
  projectId: "formsbot-e470c",
  storageBucket: "formsbot-e470c.appspot.com",
  messagingSenderId: "1077172287666",
  appId: "1:1077172287666:web:66e49c319c01fffed2f1ab",
  measurementId: "G-EMTJM0CMZW"
};
firebase.initializeApp(firebaseConfig);

createApp(App)
  .use(router)
  .mount("#app");

var newpath = checkPrivateRouting("/profile");
console.log("The new path we are getting:", newpath);
