<template>
  <div class="h-screen">
    <IndexNavbar />
    <div class="w-full p-4">
      <div class="back flex">
        <router-link to="/home">
          <span class="color-blue text-xl">&#8592;</span> back to previous
        </router-link>
      </div>
      <div class="custom-wrapper py-8">
        <div class="custom-bubbles flex items-center justify-start">
          <div class="custom-circle bg-col-blue"></div>
          <div class="custom-line bg-col-blue ml-1 mr-1"></div>
          <div class="custom-circle bg-col-blue"></div>
          <div class="custom-line bg-col-blue ml-1 mr-1"></div>
          <div class="custom-circle bg-col-blue"></div>
        </div>
        <h1 class="text-3xl my-4 font-bold color-dark">Create project</h1>
        <div class="text-base py-2 font-semibold">Name</div>
        <input
          type="text"
          name="projectName"
          id="projectName"
          class="custom-input rounded "
          placeholder="Enter Your Project Name"
          v-model="title"
        />
        <div class="text-base py-2 font-semibold">Description</div>
        <textarea
          name="projectDesc"
          id="projectDesc"
          rows="7"
          cols="40"
          class="custom-input rounded"
          placeholder="Description"
          v-model="description"
        ></textarea>
        <!-- <div class="text-base py-2 font-semibold">Add Bots</div>
        <input
          type="text"
          name="botNames"
          id="botNames"
          class="custom-input rounded "
          placeholder="Enter Your Bot Names"
        /> -->
        <br />
        <button
          class="bg-col-blue mt-4  text-white font-bold py-2 px-4 rounded"
          @click="onSubmitClick"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import { postProject } from "@/utils/api.js";

export default {
  data() {
    return {
      title: "",
      description: "",
    };
  },
  methods: {
    async onSubmitClick(e) {
      e.preventDefault();
      const fakeStartDate = "2021-06-15";
      const fakeEndDate = "2021-07-15";
      //   console.log(this.$baseurl);
      const data = await postProject(
        this.title,
        this.description,
        fakeStartDate,
        fakeEndDate
      );
      console.log(data);

      //localStorage.setItem("access_token", data.access);
      //localStorage.setItem("refresh_token", data.refresh);
      
      this.$router.push(`/createbot/${this.title}`);
    },
    clickToToggleRememberMe() {
      this.rememberMe = !this.rememberMe;
    },
    togglePasswordType() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    checkUserPresence() {
      const user = localStorage.getItem("access_token");
      if (!user) {
        this.$router.push("/login");
      }
    },
  },
  created() {
    this.checkUserPresence();
  },
  components: {
    IndexNavbar,
  },
};
</script>
<style scoped>
.custom-wrapper {
  width: 80%;
  height: auto;
  margin: 0 auto;
}
.custom-circle {
  height: 0.6em;
  width: 0.6em;
  border-radius: 50%;
}
.custom-line {
  width: 4em;
  height: 0.1em;
}
.custom-input {
  width: 60%;
  border: 2px solid var(--blue);
  background-color: transparent;
}
</style>
