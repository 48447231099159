<template>
  <div class="flex justify-center">
    <img
      src="../../assets/animations/PageLoading2.gif"
      class="custom-loading"
      alt=""
    />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.custom-loading {
  height: 40%;
  width: 40%;
}
</style>
