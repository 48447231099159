<template>
  <div class="custom-project-card  flex justify-center flex-col items-center ">
    <h3 class="uppercase">{{ project.title }}</h3>
    <p>{{ project.description }}</p>
  </div>
</template>

<script>
export default {
  props: ["project"],
};
</script>

<style scoped>
.custom-project-card {
  height: 12rem;
  width: 15rem;
  border-radius: 1rem;
  box-shadow: 10px 10px 20px -2px rgba(12, 138, 255, 0.13);
  background: #fff;
  transition: 0.3s ease;
  cursor: pointer;
}
.custom-project-card:hover {
  background: linear-gradient(
    143deg,
    rgba(33, 116, 255, 1) 0%,
    rgba(144, 181, 255, 1) 100%
  );
  box-shadow: 10px 20px 20px -2px rgba(12, 138, 255, 0.23);
  transform: translateY(-3px) scale(1.01);
  color: #fff;
}
h3 {
  font-size: 1.3rem;
  font-weight: 700;
}
</style>
