<template>
  <div>
    <IndexNavbar />
    <div
      class="homeContent flex justify-between items-center flex-col h-full relative"
    >
      <div class="w-full flex justify-around">
        <div class="custom-button-container w-full flex justify-center">
          <router-link
            to="/createproject"
            class="custom-button custom-button-project flex justify-center items-center font-semibold"
          >
            Create a new project
          </router-link>
        </div>
        <div class="custom-button-container w-full flex justify-center">
          <router-link
            to="/createbot"
            class="custom-button custom-button-bot flex justify-center items-center font-semibold"
          >
            Create your new formbot
          </router-link>
        </div>
      </div>
      <div class="custom-container-project" v-if="projects.length == 0">
        <div class="text-4xl font-bold text-center">
          Lets start creating a new project
        </div>
      </div>
      <div
        class="custom-container-project w-full flex justify-center"
        v-if="projects.length != 0"
      >
        <div
          v-for="(project, index) in projects"
          :key="index"
          class="custom-card-project"
        >
          <ProjectCard :project="project" @click="pushToCreateBot(project.title)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import ProjectCard from "@/components/ProjectCard/ProjectCard.vue";
import img from "../../assets/img/pie_chart.png";
import { getProjectDetails } from "@/utils/api.js";
import img2 from "../../assets/img/datagraph.png";

export default {
  data() {
    return {
      navbarOpen: false,
      projects: [],
      pieImage: img,
      chartImage: img2,
    };
  },
  methods: {
    setNavbarOpen: function() {
      this.navbarOpen = !this.navbarOpen;
    },
    loadProjects: async function() {
      const res = await getProjectDetails();
      console.log(res);
      this.projects = [...res.projects];
    },

    pushToCreateBot(title){
      this.$router.push(`/createbot/${title}`)
    }
  },
  created() {
    this.loadProjects();
  },
  components: {
    IndexNavbar,
    ProjectCard,
  },
};
</script>

<style scoped>
.plusSign {
  height: 1rem;
  width: 1rem;
}
</style>

<style>
body {
  background-color: #f6f8ff;
}
.colWhite {
  color: #f6f8ff;
}
.bgWhite {
  background-color: #f6f8ff;
}
</style>

<style scoped>
.homeContent {
  height: calc(100vh - 4rem);
  width: 90%;
  margin: 0 auto;
}
.custom-button-container {
  padding: 3rem 0;
}
.custom-button {
  height: 5rem;
  width: 22rem;
  border-radius: 30rem;
  box-shadow: 0px 10px 30px rgba(34, 92, 252, 0.26);
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.3s ease;
}
.custom-button:hover {
  box-shadow: 0px 15px 30px rgba(34, 92, 252, 0.36);
}
.custom-button-project {
  transition: 0.3s ease;
  border: 3px solid var(--orange);
}
.custom-button-project:hover {
  background-color: var(--orange);
  color: var(--white);
}
.custom-button-bot {
  border: 3px solid var(--blue);
  transition: 0.3s ease;
}

.custom-button-bot:hover {
  background-color: var(--blue);
  color: var(--white);
}

.colOrange {
  color: orange;
}
.colRed {
  color: red;
}
.btn-round {
  width: 12rem;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(1, 1, 1, 0.5);
}
.noBorder {
  border: none;
}
.borderBlue {
  border: 1px solid #73bcfb;
}
.pieChart {
  width: 13rem;
  height: 13rem;
}
.dataGraph {
  width: 25rem;
  height: 22rem;
}
.custom-container-project {
  height: auto;
  min-height: 60vh;
  margin-top: 3rem;
  flex-wrap: wrap;
}
.custom-card-project {
  margin-right: 3rem;
  z-index: 10;
  margin-top: 3rem;
  border-radius: 1rem;
}
.custom-card-project:nth-child(odd) {
  border-radius: 1rem;
}
</style>
